/*
--------------------------------------------
14.service
*/
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;

.service-checklist {
    & ul {
        display: flex;
        flex-wrap: wrap;

        & li {
            flex: 0 0 50%;
            list-style: none;
            color: #fff;
            opacity: 0.7;
            font-size: 14px;
            margin-bottom: 15px;

            @include mq(xs) {
                flex: 0 0 100%;
            }

            & i {
                font-size: 13px;
                margin-right: 7px;
            }
        }
    }
}
.sasup-service-action {
    margin-top:8px;
}

.service-slider-shapes {
    &>* {
        position: absolute;
    }

    & .shape-1 {
        width: 180px;
        height: 180px;
        background-color: #fbc15d;
        border-radius: 50%;
        top: calc(-180px + 56px);
        left: 59px;

        @include mq(xl) {
            width: 150px;
            height: 150px;
        }
    }

    & .shape-2 {
        width: 120px;
        border-radius: 50%;
        background-color: #fd8697;
        height: 120px;
        top: 215px;
        right: calc(-120px + 40px);

        @include mq(xl) {
            width: 100px;
            height: 100px;
        }

        @include mq(lg) {
            right: calc(-120px + 20px);
        }
    }

    & .shape-3 {
        top: 180px;
        right: 260px;

        @include mq(xxl) {
            top: 50px;
            right: 50px;
        }

        & img {
            -webkit-animation: hero-triangle-1 6s linear infinite;
            -moz-animation: hero-triangle-1 6s linear infinite;
            -o-animation: hero-triangle-1 6s linear infinite;
            animation: hero-triangle-1 6s linear infinite;

            @include mq(xl) {
                opacity: 0.15;
            }

            @include mq(lg) {
                opacity: 0.15;
            }
        }
    }

    & .shape-4 {
        right: 420px;
        bottom: 115px;

        @include mq(lg) {
            bottom: 58px;
        }

        @include mq(md) {
            bottom: 15px;
        }

        @include mq(sm) {
            right: 317px;
            bottom: 31px;
        }

        & img {
            -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
            -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
            -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
            animation: hero-thumb-sm-animation 4s linear infinite alternate;
        }
    }
}

.service-3-space {
    @include mq(xl) {
        padding-top: 80px;
    }
}

.sasup-service-img-wrap-space-3 {
    @include mq(lg) {
        padding-right: 0;
    }

    @include mq(md) {
        padding-right: 0;
        margin-bottom: 50px;
    }

    @include mq(sm) {
        padding-right: 0;
        margin-bottom: 50px;
    }
}

.sasup-service-img-wrap {
    & img {
        @include mq(lg) {
            max-width: 205px;
        }
    }
}

.sasup-single-dashbord-thumb,
.sasup-single-service-thumb {
    & img {
        @include mq(xs) {
            max-width: 150px;
        }
    }
}

.sasup-single-list {

    @include mq(xs) {
        display: flex;
    }

    &>* {
        display: inline-block;
    }
}

.sasup {
    &-icon {
        vertical-align: top;
        margin-top: 4px;

        & i {
            color: #0054B8;
            font-size: 16px;
            margin-right: 12px;
            display: inline-block;
        }
    }

    &-content {
        & p {
            color: #535559;
            font-size: 15px;

            & span {
                font-weight: 300;
                color: #000;
            }
        }
    }
}

.service-right-thumbs {
    position: relative;

    &>img {
        max-width: inherit;

        @include mq(xl) {
            max-width: 100%;
        }

        @include mq(lg) {
            max-width: 100%;
        }

        @include mq(md) {
            max-width: 100%;
        }

        @include mq(sm) {
            max-width: 100%;
        }

        @include mq(xs) {
            max-width: 100%;
        }
    }
}

.order-first {

    &-xs {
        @include mq(xs) {
            order: -1;
        }
    }

    &-sm {
        @include mq(sm) {
            order: -1;
        }
    }

    &-md {
        @include mq(md) {
            order: -1;
        }
    }

    &-lg {
        @include mq(lg) {
            order: -1;
        }
    }

    &-xl {
        @include mq(xl) {
            order: -1;
        }
    }

}

.service-right-thumbs-main {
    position: relative;

    &>img {
        max-width: inherit;
        max-height:500px;
        box-shadow: 0px 0px 10px rgba(0,0,0,.2);
        margin-top:auto;
        margin-bottom:auto;
        vertical-align: middle;

        @include mq(xl) {
            max-width: 100%;
        }

        @include mq(lg) {
            max-width: 100%;
        }

        @include mq(md) {
            max-width: 100%;
        }

        @include mq(sm) {
            max-width: 100%;
        }

        @include mq(xs) {
            max-width: 100%;
        }
    }
}

.service-left-thumbs {
    position: relative;

    & .dashbord-1 {
        z-index:10 !important;
        top:0 !important;
        right:0 !important;
        
        & img {
            box-shadow:0px 0px 10px rgba(0,0,0,0.6) !important;
            max-height:250px !important;
            width:auto;

            @include mq(sm) {
                max-height:250px !important;
                width:auto;
            }

            @include mq(xs) {
                max-height:150px !important;
                width:auto;
            }
        }
    }

    & .dashbord-2 {
        z-index:2 !important;
        top:0 !important;
        left:10% !important;

        & img {
            max-height:200px !important;
            width:auto;
            box-shadow:0px 0px 10px rgba(0,0,0,0.6) !important;
            

            @include mq(sm) {
                max-height:200px !important;
                width:auto;
            }

            @include mq(xs) {
                max-height:150px !important;
                width:auto;
            }
        }
    }

    & .dashbord-3 {
        z-index:11 !important;
        left:20% !important;
        top:40% !important;
        & img { 
            box-shadow:0px 0px 10px rgba(0,0,0,0.6) !important;
            max-height:250px !important;
            width:auto;

            @include mq(sm) {
                max-height:200px !important;
                width:auto;
            }

            @include mq(xs) {
                max-height:150px !important;
                width:auto;
            }
        }
    }

    & .dashbord-4 {
        z-index:12 !important;
        bottom:0;
        right:0 !important;
        & img { 
            box-shadow:0px 0px 10px rgba(0,0,0,0.6) !important;
            max-height:250px !important;
            width:auto;

            @include mq(sm) {
                max-height:200px !important;
                width:auto;
            }

            @include mq(xs) {
                max-height:150px !important;
                width:auto;
            }
        }
    }

    & img {
        z-index:0 !important;
        max-width: inherit;
    }

    &>img {
        @include mq(lg) {
            max-width: calc(100% + 46px);
        }

        @include mq(md) {
            max-width: 100%;
        }

        @include mq(sm) {
            max-width: 100%;
        }

        @include mq(xs) {
            max-width: 100%;
        }
    }
}

.service-dashbord-thumb {
    &>.single-dashbord {
        position: absolute;
        border-radius: 4px;
        overflow: hidden;
    }

    & .dashbord-1 {
        top: -20% !important;
        z-index: 9;
        left: 20%;
        /*box-shadow: 5.209px 29.544px 60px 0px #20140033;*/
        -webkit-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -moz-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -o-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        animation: hero-thumb-sm-3-animation 4s linear infinite alternate;

        @include mq(lg) {
            left: 27px;
        }

        & img {
            max-height:350px;
            width:auto;

            @include mq(lg) {
                max-width: 250px;
            }
            
            @include mq(md) {
                max-width: 250px;
            }

            @include mq(sm) {
                max-width: 250px;
            }

            @include mq(xs) {
                max-width: 200px;
            }
        }

    }

    & .dashbord-2 {
        top: 52px;
        right: 30px;
        z-index:3;
        /*box-shadow: 0px 30px 60px 0px #613c0124;*/
        -webkit-animation: about-sm 2s linear infinite alternate;
        -moz-animation: about-sm 2s linear infinite alternate;
        -o-animation: about-sm 2s linear infinite alternate;
        animation: about-sm 2s linear infinite alternate;

        & img {
            max-height:400px;
            width:auto;
            

            @include mq(lg) {
                max-width: 200px;
            }
            
            @include mq(md) {
                max-width: 200px;
            }

            @include mq(sm) {
                max-width: 200px;
            }

            @include mq(xs) {
                max-width: 200px;
            }
        }
    }

    & .dashbord-2-port {
        top: 52px;
        right: 30px;
        z-index:3;
        /*box-shadow: 0px 30px 60px 0px #613c0124;*/
        -webkit-animation: about-sm 2s linear infinite alternate;
        -moz-animation: about-sm 2s linear infinite alternate;
        -o-animation: about-sm 2s linear infinite alternate;
        animation: about-sm 2s linear infinite alternate;

        & img {
            max-height:400px;
            width:auto;
            

            @include mq(lg) {
                max-width: 200px;
            }
            
            @include mq(md) {
                max-width: 200px;
            }

            @include mq(sm) {
                max-width: 200px;
            }

            @include mq(xs) {
                max-width: 150px;
            }
        }
    }

    & .dashbord-3 {
        
        right: 0;
        top: 150px;
        -webkit-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -moz-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -o-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        animation: hero-thumb-sm-3-animation 4s linear infinite alternate;

        & img {
            @include mq(lg) {
                max-width: 300px;
            }
            
            @include mq(md) {
                max-width: 300px;
            }

            @include mq(sm) {
                max-width: 250px;
            }

            @include mq(xs) {
                max-width: 200px;
            }
        }
    }

    & .dashbord-3-b {
        
        left: 0px;
        top: 20px;

        -webkit-animation: about-sm 3s linear infinite alternate;
        -moz-animation: about-sm 3s linear infinite alternate;
        -o-animation: about-sm 3s linear infinite alternate;
        animation: about-sm 3s linear infinite alternate;

        & img {
            max-height:325px;
            width:auto;

            @include mq(lg) {
                max-width: 250px;
            }
            
            @include mq(md) {
                max-width: 300px;
            }

            @include mq(sm) {
                max-width: 250px;
            }

            @include mq(xs) {
                max-width: 200px;
            }
        }
    }

    & .dashbord-4 {
        
        bottom:0 !important;
        right:0 !important;
        -webkit-animation: about-sm 3s linear infinite alternate;
        -moz-animation: about-sm 3s linear infinite alternate;
        -o-animation: about-sm 3s linear infinite alternate;
        animation: about-sm 3s linear infinite alternate;

        & img {
            max-height:400px;
            width:auto;

            @include mq(lg) {
                max-width: 250px;
            }
            
            @include mq(md) {
                max-width: 250px;
            }

            @include mq(sm) {
                max-width: 250px;
            }

            @include mq(xs) {
                max-width: 200px;
            }
        }

    }

    &.style-2 {
        & .dashbord-1 {
            right: 70px;
            left: inherit;

            @include mq(lg) {
                right: -25px;
            }

            @include mq(sm) {
                right: 0;
            }
        }

        & .dashbord-2 {
            left: 55px;
            right: inherit;
        }

        & .dashbord-3 {
            left: 16px;
            right: auto;
        }
    }
}

.service-dashbord-thumb-main {
    &>.single-dashbord {
        position: absolute;
        border-radius: 4px;
        overflow: hidden;
    }

    & .dashbord-middle {
        bottom: 20%;
        z-index: 10;
        left: 40%;
        /*box-shadow: 5.209px 29.544px 60px 0px #20140033;*/
        -webkit-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -moz-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -o-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        animation: hero-thumb-sm-3-animation 4s linear infinite alternate;

        & img {
            max-height:250px;
            width:auto;

            @include mq(lg) {
                max-width: 250px;
            }

            @include mq(sm) {
                max-width: 250px;
            }

            @include mq(xs) {
                max-width: 100px;
            }
        }

    }

    & .dashbord-1 {
        top:40%;
        z-index: 9;
        left:15%;
        /*box-shadow: 5.209px 29.544px 60px 0px #20140033;*/
        -webkit-animation: about-sm 4s linear infinite alternate;
        -moz-animation: about-sm 4s linear infinite alternate;
        -o-animation: about-sm 4s linear infinite alternate;
        animation: about-sm 4s linear infinite alternate;

        & img {
            max-height:250px;
            width:auto;

            @include mq(lg) {
                max-width: 200px !important;
            }

            @include mq(md) {
                max-width: 100px !important;
            }

            @include mq(sm) {
                max-width: 100px !important;
            }

            @include mq(xs) {
                max-width: 100px !important;
            }
        }

        @include mq(md) {
            top:20%;
        }

        @include mq(sm) {
            top:20%;
        }

        @include mq(xs) {
            top:15%
        }

    }

    & .dashbord-2 {
        top: 0%;
        right: 3%;
        z-index:6;
        /*box-shadow: 0px 30px 60px 0px #613c0124;*/
        -webkit-animation: about-sm 2s linear infinite alternate;
        -moz-animation: about-sm 2s linear infinite alternate;
        -o-animation: about-sm 2s linear infinite alternate;
        animation: about-sm 2s linear infinite alternate;

        & img {
            max-height:325px;
            width:auto;
            

            @include mq(lg) {
                max-width: 100%;
                max-height:300px;
            }

            @include mq(md) {
                max-width: 100%;
                max-height:300px;
            }

            @include mq(sm) {
                max-width: 100%;
                max-height:300px;
            }

            

            @include mq(xs) {
                max-height:200px;            
            }
        }
    }

    & .dashbord-3 {
        
        z-index:10;
        right: 5%;
        top: 25%;
        -webkit-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -moz-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        -o-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
        animation: hero-thumb-sm-3-animation 4s linear infinite alternate;

        & img {
            max-height:250px;
            width:auto;

            @include mq(lg) {
                max-width: 100%;
                max-height:250px;
            }

            @include mq(md) {
                max-width: 100%;
                max-height:200px;
            }

            @include mq(sm) {
                max-width: 100%;
                max-height:200px;
            }

            @include mq(xs) {
                max-height:200px;            
            }
        }

        @include mq(md) {
            top:20%;
        }

        @include mq(sm) {
            top:20%;
        }

        @include mq(xs) {
            top:15%
        }
    }

    & .dashbord-4 {
        box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        z-index:5;
        bottom:50%;
        left:10% !important;
        -webkit-animation: about-sm 3s linear infinite alternate;
        -moz-animation: about-sm 3s linear infinite alternate;
        -o-animation: about-sm 3s linear infinite alternate;
        animation: about-sm 3s linear infinite alternate;

        & img {
            max-height:250px;
            width:auto;

            @include mq(lg) {
                
                max-width: 100%;
                max-height:200px;
            }

            @include mq(md) {
                top:0;
                max-width: 100%;
                max-height:170px;
            }

            @include mq(sm) {
                top:0;
                max-width: 100%;
                max-height:170px;
            }

            

            @include mq(xs) {
                max-height:170px;            
            }
        }
    }

    &.style-2 {
        & .dashbord-1 {
            right: 70px;
            left: inherit;

            @include mq(lg) {
                right: -25px;
            }

            @include mq(sm) {
                right: 0;
            }
        }

        & .dashbord-2 {
            left: 55px;
            right: inherit;
        }

        & .dashbord-3 {
            left: 16px;
            right: auto;
        }
    }
}

.pl-68 {
    padding-left: 68px;
}

.section-title-extra-info {
    font-size: 15px;
    font-weight: 300;
    color: #0a1426;
    padding-bottom: 5px;
}

.sesup-service-desc-info {
    margin-bottom: 37px;

    & p {
        font-size: 15px;
        line-height: 24px;
        color: #535559;
    }
}

.hidden-lg-br {
    & br {
        @include mq(lg) {
            display: none;
        }
    }
}

.service_right {
    @include mq(md) {
        padding-left: 0;
    }

    @include mq(sm) {
        padding-left: 0;
    }

    @include mq(xs) {
        padding-left: 0;
    }
}

.service-info-wrapper {
    padding: 95px 70px;
    padding-bottom: 89px;
    padding-right: 69px;

    @include mq(xs) {
        padding: 52px 29px;
        padding-bottom: 52px;
        padding-right: 29px;
    }

    @include mq(lg) {
        padding: 65px 38px;
        padding-bottom: 65px;
        padding-right: 38px;
    }

    @include mq(md) {
        padding: 45px 35px;
        padding-bottom: 45px;
        padding-right: 35px;
    }

    & .icon {
        & svg {
            width: 50px;
            height: 50px;
            @include transition(.3s);
        }
    }

    &:hover {
        & svg {
            -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-perspective: 1000px;
            perspective: 1000px;
        }
    }
}

.service-info {
    &-title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 8px;

        @include mq(xs) {
            font-size: 28px;
        }

        & a {
            text-decoration: none;

            &:hover {
                color: var(--clr-theme-1);
            }
        }
    }

    &-desc {
        font-size: 15px;
        line-height: 24px;
        opacity: .7;
        margin-bottom: 37px;
    }
}

.service-read-more-btn {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;

    &:hover {
        color: var(--clr-theme-1);
    }

    & i {
        font-size: 12px;
        font-weight: 500;
        margin-left: 3px;
        display: inline-block;
    }
}

.single-service-img-inner {
    & .service-img-1 {
        width: 360px !important;
        height: 200px;
        object-fit: cover;
        border-radius: 4px;

        @include mq(xs) {
            height: inherit;
        }

        @include mq(md) {
            height: inherit;
        }
    }

    & .service-img-2 {
        width: 200px !important;
        border-radius: 4px;
        height: 220px !important;
        object-fit: cover;
        position: relative;
        z-index: 9;
        object-position: top left;

        @include mq(xs) {
            width: 114px !important;
            height: 126px !important;
        }
    }
}

.service-img-boxed-group {
    padding: 75px 70px;

    @include mq(xs) {
        padding: 52px 25px;
        padding-bottom: 20px;
    }

    @include mq(lg) {
        padding: 65px 38px;
        padding-right: 71px;
    }

    @include mq(md) {
        padding: 54px 27px;
    }
}

.single-service-img.negative-space {
    margin-top: -150px;

    @include mq(xs) {
        margin-top: -81px;
        margin-right: 53px;
    }
}

.single-service-img {
    &.has-shadow {
        box-shadow: 0px 30px 60px 0px #00444733;
    }
}

.animated-top-bottom {
    -webkit-animation: hero-thumb-animation 3s linear infinite alternate;
    -moz-animation: hero-thumb-animation 3s linear infinite alternate;
    -o-animation: hero-thumb-animation 3s linear infinite alternate;
    animation: hero-thumb-animation 3s linear infinite alternate;
}

.animated-cornar {
    -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-animation 4s linear infinite alternate;
    box-shadow: -11.722px -27.615px 60px 0px #00153e1a;
}

.custom-h-100 {
    height: 99%;
}

// list service

.service-count-number {
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    @include transition(.3s);
    border: 2px solid #dbdde3;
    margin-right: 20px;
    border-radius: 50px;
    color: #0a1426;
    font-weight: 600;

    @include mq(xs) {
        margin-right: 15px;
    }
}

.service-title-list {
    font-size: 18px;
    font-weight: 700;
    display: block;
    color: #0a1426;
    margin-bottom: 10px;

    @include mq(xs) {
        margin-bottom: 5px;
    }

    & a {
        text-decoration: none;

        &:hover {
            color: var(--clr-theme-1);
        }
    }
}

.service-content-list {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #535559;

    @include mq(xs) {
        font-size: 14px;
        line-height: 21px;
    }

    & br {
        @include mq(lg) {
            display: none;
        }
    }
}

.single-service-list {
    display: flex;

    @include mq(xs) {
        margin-bottom: 20px;
    }

    @include mq(md) {
        margin-bottom: 20px;
    }

    &:hover .service-count-number {
        color: #fff;
        background: var(--clr-theme-1);
        border-color: transparent;
    }
}

.service-left-2-thumb {
    & img {
        border-radius: 4px;
    }
}

.service-right-area-2 {
    padding-right: 11px;

    @include mq(xl) {
        padding-left: 20px;
    }

    @include mq(lg) {
        padding-left: 20px;
    }

    @include mq(sm) {
        padding-left: 0;
    }

    @include mq(xs) {
        padding-left: 0;
    }
}

.service-right-list-item {
    list-style: none;
    font-size: 14px;
    margin-bottom: 15px;
    color: #0a1426;

    & i {
        font-size: 14px;
        font-weight: 400;
        color: #0054B8;
        margin-right: 3px;
    }
}

.w-list-50 {
    & ul {
        display: flex;
        flex-wrap: wrap;

        &>li {
            flex: 0 0 50%;
            position: relative;

            @include mq(sm) {
                flex: 0 0 50%;
            }

            @include mq(xs) {
                flex: 0 0 100%;
            }
        }
    }
}

.service-right-list-2 {
    @include mq(md) {
        padding-right: 80px;
    }
}

.service-left-2 {
    @include mq(sm) {
        padding-left: 0;
    }

    @include mq(xs) {
        padding-left: 0;
    }
}

// service details
.service-details-breadcrumb {
    &-btn {
        font-size: 13px;
        font-weight: 400;
        color: #0a1426;
        background: #fff;
        height: 24px;
        display: inline-block;
        padding: 0 14px;
        border-radius: 4px;
        line-height: 24px;
        margin-bottom: 18px;
    }
}

.service-details-img {
    overflow: hidden;
}

.service-details-content {
    &-title {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #0a1426;

        @include mq(xs) {
            font-size: 23px;
        }
    }

    &-info {
        font-size: 24px;
        color: #0a1426;
        line-height: 34px;
        margin-bottom: 23px;

        @include mq(lg) {
            font-size: 18px;
            line-height: 1.6;
        }

        @include mq(xs) {
            font-size: 15px;
            line-height: 1.6;
        }
    }

    &-desc {
        line-height: 26px;
        color: #535559;
        margin-bottom: 25px;
    }
}

.mb-43 {
    margin-bottom: 43px;
}

// service list section
.service-details-thumb-list {
    overflow: hidden;

    & .thumb {
        float: left;
        margin-right: 30px;

        @include mq(lg) {
            float: none;
            margin-right: 0;
            margin-bottom: 30px;
        }

        @include mq(xs) {
            float: none;
            margin-right: 0;
            margin-bottom: 30px;
        }
    }

    & .content {
        overflow: hidden;
        padding-right: 75px;

        @include mq(xs) {
            padding-right: 0;
        }
    }

    &-title {
        font-size: 24px;
        font-weight: 600;
        color: #0a1426;
        margin-bottom: 11px;
    }

    &-desc {
        line-height: 24px;
        color: #535559;
        margin-bottom: 19px;
    }
}

.service-details-thumb-list-dotted {
    & li {
        font-size: 15px;
        line-height: 22px;
        padding-left: 20px;
        color: #535559;
        position: relative;
        margin-bottom: 14px;
        list-style-type: none;

        &::after {
            position: absolute;
            left: 0;
            top: 5px;
            width: 7px;
            border-radius: 50px;
            height: 7px;
            background-color: #0054B8;
            content: "";
        }
    }
}

.service-feature-boxed-2 {
    background-color: var(--clr-common-white);
    box-shadow: 0px 30px 70px 0px #0005271a;
    border-radius: 4px;
    padding: 50px 40px;

    @include mq(lg) {
        padding: 39px 20px;
    }

    @include mq(xs) {
        padding: 35px 22px;
    }

    &:hover .service-feature-boxed-2-thumb {
        & img {
            animation-name: animation-pulse-shrink;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }

    &-thumb {
        margin-bottom: 35px;
    }

    &-content {
        &-title {
            font-size: 20px;
            font-weight: 600;
            color: #0a1426;
            margin-bottom: 14px;

            @include mq(lg) {
                font-size: 18px;
            }

            & a {
                text-decoration: none;
            }

            &:hover {
                color: var(--clr-theme-1);
            }
        }

        &-desc {
            font-size: 15px;
            line-height: 24px;
            color: #535559;
            margin-bottom: 0;

            @include mq(lg) {
                font-size: 13px;
            }
        }
    }
}

.breadcrumb-area-space-2 {
    @include mq(lg) {
        padding-top: 180px;
        padding-bottom: 78px;
    }

    @include mq(md) {
        padding-top: 190px;
        padding-bottom: 78px;
    }

    @include mq(xs) {
        padding-top: 155px;
        padding-bottom: 90px;
    }

    @include mq(sm) {
        padding-bottom: 70px;
    }
}

.service-details-breadcrumb-title {
    @include mq(xs) {
        font-size: 25px;
    }

    & br {
        @include mq(xs) {
            display: none;
        }
    }
}

.single-blog-list {
    & .thumb {
        margin:auto;
        height:196px;
        width:350px;
        
        
        & img {
            margin:auto;
            height:auto;
            width:100%;
            

            @include mq(lg) {
                max-width: 327px;
            }

            @include mq(sm) {
                max-width: 326px;
                float:none !important;
            }

            @include mq(xs) {
                max-width: 100%;
            }
        }
    }
}

// service details
.service-breadcrumb-meta-single {
    margin-right: 30px;
    font-size: 14px;

    @include mq(xs) {
        margin-right: 12px;
    }

    @include mq(sm) {
        margin-right: 30px;
        font-size: 14px;
    }

    & i {
        font-size: 13px;
        margin-right: 10px;
    }
}

.service-breadcrumb {
    &-subtitle {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 3px;
    }

    &-title {
        font-size: 60px;
        line-height: 70px;
        font-weight: 700;

        @include mq(md) {
            font-size: 44px;
            line-height: 1.4;
        }

        @include mq(xs) {
            font-size: 28px;
            line-height: 1.3;
        }

        @include mq(sm) {
            font-size: 36px;
            line-height: 1.5;
        }
    }
}

.service-details-shapes>* {
    position: absolute;
}

.service-details-shapes {
    & img {
        &:nth-child(1) {
            right: 66px;
            top: -33px;
            -webkit-animation: hero-triangle-1 6s linear infinite;
            -moz-animation: hero-triangle-1 6s linear infinite;
            -o-animation: hero-triangle-1 6s linear infinite;
            animation: hero-triangle-1 6s linear infinite;

            @include mq(lg) {
                right: 155px;
            }

            @include mq(xs) {
                right: 127px;
                top: -89px;
            }
        }

        &:nth-child(2) {
            top: -60px;
            left: -107px;
            -webkit-animation: services-triangle 7s linear infinite alternate;
            -moz-animation: services-triangle 7s linear infinite alternate;
            -o-animation: services-triangle 7s linear infinite alternate;
            animation: services-triangle 7s linear infinite alternate;

            @include mq(lg) {
                left: 0;
            }
        }
    }
}

.service-outside-shapes {
    & img {
        position: absolute;
        right: 0;
        top: 170px;

        @include mq(lg) {
            top: 71px;
        }

        @include mq(xs) {
            top: 43px;
        }
    }
}

.s-details {
    &-subtitle {
        font-size: 15px;
        color: #535559;
        font-weight: 400;
        display: block;
        margin-bottom: 12px;
    }

    &-title {
        font-size: 50px;
        font-weight: 600;
        color: #0a1426;
    }
}

.service-details-content {
    position: relative;
    z-index: 9;
}

/**
* service style 4
*/
.sasup-service {
    &-box {
        &-img-4 {

            margin: 0 auto;
            text-align:center;

            & img {
                box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
                max-height: 500px;
                height: 147.2px;
            }
        }

        &-img-4-no-shadow {
            
            box-shadow: none !important;
            text-align:center;
            margin: auto;

            & img {
                max-height: 500px;
                height: 147.2px;
            }
        }

        &-title-4 {
            font-family: $space;
            font-weight: 700;
            font-size: 22px;
            letter-spacing: -0.02em;
            color: var(--clr-body-heading);
            margin-bottom: 12px;
            margin-top:12px;

            & a {
                text-decoration: none;

                &:hover {
                    color: var(--clr-theme-1);
                }
            }
        }

        &-text-4 {
            font-family: $roboto;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 15px;
            color: var(--clr-body-gray);
        }
    }
}

.sasup-service-box-read-more-link-4 {
    font-family: $roboto;
    font-weight: 500;
    font-size: 14px;
    color: var(--clr-common-black-2);
    text-decoration: none;

    &:hover {
        color: var(--clr-theme-1);
    }

    & i {
        font-weight: 600;
        font-size: 12px;
        vertical-align: middle;
        margin-left: 3px;
    }
}

.sasup-vertical-animated-line-4 {
    position: absolute;
    right: -30px;
    height: 330px;
    top: 0;
    width: 1px;
    background: var(--clr-body-soft-bg-2);

    @include mq(xs) {
        display: none;
    }

    &::after {
        position: absolute;
        left: -1px;
        top: 0;
        width: 3px;
        height: 30px;
        background-color: var(--clr-theme-3);
        animation-timing-function: linear;
        animation-name: topBottomLine;
        animation-duration: 9s;
        animation-iteration-count: infinite;
        content: "";
    }

    &.slow {
        right: -55px;

        &::after {
            animation-delay: 2s;
        }
    }
}

/**
* features about style 4
*/
.sasup-features-about-dashbord {
    &-img {
        &-4-1 {
            border-radius: 10px;
            filter: drop-shadow(0px 20px 50px rgba(0, 74, 37, 0.08));
            margin-bottom: 18px;
            overflow: hidden;
        }

        &-4-2 {
            filter: drop-shadow(0px 20px 50px rgba(0, 56, 28, 0.08));
            border-radius: 10px;
            overflow: hidden;
        }

        &-4-3 {
            filter: drop-shadow(0px 20px 50px rgba(0, 56, 28, 0.08));
            border-radius: 10px;
            margin-bottom: 20px;
            overflow: hidden;
        }

        &-4-4 {
            overflow: hidden;
            filter: drop-shadow(0px 30px 50px rgba(0, 56, 28, 0.06));
        }
    }

    &-has-before {
        position: relative;
        padding: 0 75px;
        padding-top: 50px;

        @include mq(xs) {
            padding: 0;
        }

        &::before {
            background: linear-gradient(258.69deg, #F8F3EA 0.26%, #DBF4EA 99.99%);
            border-radius: 14px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            height: 320px;
            content: "";
        }
    }
}

.sasup-features-about-box-main-space.pl-10.pr-90 {
    @include mq(xs) {
        padding-right: 0;
        margin-bottom: 120px;
    }
}

.sasup-features-about-custom-row {
    &>* {
        &:nth-child(1) {
            width: 44.6%;
        }

        &:nth-child(2) {
            width: calc(100% - 44.6%);
        }
    }
}

.sasup-features-about {
    &-left-shape-4 {
        position: absolute;
        left: -28.7%;
        top: -104px;
        z-index: -2;

        &-2 {
            position: absolute;
            top: 70.8%;
            left: 22px;
        }
    }
}