/*
--------------------------------------------
7.header
*/
@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.sasup-header {
    & ul {
        & li {
            display: inline-block;
            list-style-type: none;
            margin-right: 45px;
            position: relative;

            @include mq(lg) {
                margin-right: 23px;
            }

            & a {
                color: var(--clr-common-white);
                text-decoration: none;
                font-size: 15px;
                font-weight: 500;
                padding: 29px 0;
                display: inline-block;
            }

            & .sub-menu {
                background: #ffffff none repeat scroll 0 0;
                box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
                left: 0;
                position: absolute;
                z-index: 99;
                top: 119%;
                transition: all 0.3s ease 0s;
                width: 240px;
                opacity: 0;
                visibility: hidden;
                z-index: 999;
                border-top: 4px solid #ebebeb;
                text-align: left;
                padding: 15px 0;

                & li {
                    display: block;
                    margin: 0px;
                    padding: 8px 25px 8px 25px;

                    & a {
                        padding: 0px;
                        display: inline-block;
                        text-transform: capitalize;
                        font-weight: 500;
                        color: #212237;
                        position: relative;
                    }

                    &:hover {
                        &>a {
                            color: #0054B8;
                        }
                    }
                }
            }

            &:hover {
                &>a {
                    color: #0054B8;
                }

                & .sub-menu {
                    opacity: 1;
                    visibility: visible;
                    top: 100%;
                }
            }
        }
    }

    &-2 {
        & ul {
            & li {
                & a {
                    color: #0a1426;
                }

                &:hover {
                    &>a {
                        color: #0054B8;
                    }
                }

                & .sub-menu {
                    border-color: #0054B8;

                    & li:hover>a {
                        color: #0054B8;
                    }
                }
            }
        }
    }

    &-3 {
        & ul {
            & li {
                margin-right: 0;
                margin-left: 35px;

                & a {
                    color: #000;
                    text-shadow: 0px 0px 10px rgba(255,255,255,0.6);
                }

                &:hover {
                    &>a {
                        //color:#ebebeb;
                        /*text shadow on hover*/
                        text-shadow: 0px 0px 10px rgba(135, 229, 255, 0.3);
                    }
                }

                & .sub-menu {
                    border-color: #E97472;

                    & li:hover>a {
                        color:#000;
                        text-shadow: 0px 0px 10px rgba(255,255,255,0.6);
                    }
                }
            }
        }
    }
}

.header-transparent {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
}

.bg-default {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-top-left {
    background-repeat: no-repeat;
    background-position: top left;
}
.yellow-text {
    /* dropshadow to help with light background*/
    //text-shadow: 0px 0px 5px rgba(0,0,0,0.8);
    color: rgb(255, 255, 255);
    
    margin-left:auto;
    margin-right:auto;
    
    margin-bottom:20px;
    font-weight:500;
    

    font-size:23px;
    margin-top:20px;
    max-width:600px;

    
    @include mq(xl) {
        font-size: 20px;
        margin-top:20px;
        max-width:600px;
    }

    @include mq(lg) {
        font-size: 18px;
        margin-top:20px;
        max-width:500px;
    }

    @include mq(md) {
        font-size: 15px;
        max-width:390px;
        margin-top:20px;
        max-width:390px;
    }

    @include mq(sm) {
        font-size: 14px;
        max-width:320px;
    }

    @include mq(xs) {
        font-size: 14px;
        max-width:320px;
    }
    
}

.start-writing {
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;

    @include mq(lg) {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;

    }

    @include mq(sm) {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;

    }

    @include mq(xs) {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;

    }
}



.blur-light {
    /*background-color: rgba(255,179,0,0.6);*/
    color: #fff;
    //text-shadow: 0px 0px 5px rgba(0,0,0,0.9);
    font-weight: 900;

    

   
}

.highlight {
    background-color: rgba(255,179,16,0.5);
}

.sasup-hero {
    &-content-sub {
        color: var(--clr-common-white);
        font-size: 18px;
        margin-bottom: 14px;
        display: block;

        @include mq(sm) {
            font-size: 18px;
            margin-bottom: 14px;
        }

        @include mq(xs) {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    &-content-main {
        font-size: 50px;
        line-height: 1.086;
        font-weight: 600;
        color: #fff;
        margin-left:auto;
        margin-right:auto;
        

        margin-bottom:30px;
        margin-top:10px;
    

        @include mq(lg) {
            font-size: 40px;
            margin-top:40px;
        }

        @include mq(md) {
            font-size: 30px;
            max-width:350px;
            margin-top:60px;
            
        }

        @include mq(sm) {
            font-size: 20px;
            max-width:370px;
            margin-top:60px;
        }

        @include mq(xs) {
            font-size: 25px;
            max-width:350px;
            margin-top:70px;
        }
    }
}

.dashbord-shadow {
    box-shadow: 1px 11px 106px #00000021;
}

// sidebar css

/* sidebar area start */


.sidebar {
    &__area {
        position: fixed;
        right: -340px;
        top: 0;
        width: 320px;
        height: 100%;
        background: $white none repeat scroll 0 0;
        overflow-y: scroll;
        @include box-shadow(-5px 0 20px -5px rgba(#000000, .86));
        -webkit-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -moz-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        z-index: 9999;

        &.sidebar-opened {
            right: 0px;
        }
    }

    &__wrapper {
        position: relative;
        padding: 30px;
    }

    &__close {
        position: absolute;
        top: 30px;
        right: 80px;

        &-btn {
            transition: all 450ms cubic-bezier(.4, .25, .3, 1.3);
            width: 40px;
            height: 40px;
            position: absolute;
            text-align: center;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $black;
            border: 1px solid rgba(#000, .9);
            border-radius: 50%;
            cursor: pointer;
            overflow: hidden;
            background: transparent;
            z-index: 99;

            &:focus {
                border: 1px solid $black;
            }

            & span {
                transition: all 400ms cubic-bezier(.4, .25, .3, 1.3) 100ms;
                position: absolute;
                top: 47%;
                left: 50%;
                font-size: 14px;
                font-weight: 900;
                line-height: 40px;
                vertical-align: middle;

                &:first-of-type {
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                }

                &:last-of-type {
                    text-transform: uppercase;
                    transform: translate(-50%, 50%);
                    -webkit-transform: translate(-50%, 50%);
                    -moz-transform: translate(-50%, 50%);
                    -ms-transform: translate(-50%, 50%);
                    -o-transform: translate(-50%, 50%);
                }
            }

            &:hover {
                width: 80px;
                border-radius: 0;

                & span:first-of-type {
                    transform: translate(-50%, -150%);
                    -webkit-transform: translate(-50%, -150%);
                    -moz-transform: translate(-50%, -150%);
                    -ms-transform: translate(-50%, -150%);
                    -o-transform: translate(-50%, -150%);
                }

                & span:last-of-type {
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                }
            }
        }
    }
}


.sidebar-toggle-btn {

    @include mq(md) {
        margin-left: 50px;
    }

    @include mq(sm) {
        margin-left: 30px;
    }

    @include mq(xs) {
        margin-left: 30px;
    }

    & .line {
        width: 30px;
        height: 3px;
        background-color: $w-purple;
        display: block;
        margin: 6px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

    }

    &:hover {
        cursor: pointer;

        & .line {
            -webkit-transform: rotate(-30deg);
            -ms-transform: rotate(-30deg);
            -o-transform: rotate(-30deg);
            -moz-transform: rotate(-30deg);
            transform: rotate(-30deg);

            &:nth-child(1) {
                width: 10px;
            }

            &:nth-child(2) {
                width: 20px;
            }
        }
    }

    &-2 {
        & .line {
            background-color: $w-blue;
        }
    }

    &-2 {
        & .line {
            background-color: $w-purple-4;
        }
    }

    &-5 {
        & .line {
            background-color: $w-blue-7;
        }
    }
}


.body-overlay {
    background-color: rgba(#000000, .5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    opacity: 0;
    visibility: hidden;
    @include transition(.3s);
}

.body-overlay.opened {
    opacity: 1;
    visibility: visible;
}

.header-main {
    @include mq(lg) {
        padding: 27px 0;
    }

    @include mq(md) {
        padding: 27px 0;
    }

    @include mq(sm) {
        padding: 20px 0;
    }

    @include mq(xs) {
        padding: 19px 0;
    }
}

// mobile header
.mobile-bar-control {
    margin-left: 20px;
    cursor: pointer;
    vertical-align: middle;

    & .line {
        width: 30px;
        height: 3px;
        background-color: #7127ea;
        display: block;
        margin: 6px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    &-white {
        & .line {
            background-color: #fff;
        }
    }
}

.sasup-broder-btn-space-3 {
    margin-left: 20px;
}

.bar-control-clicked {
    & .line {
        transform: rotate(20deg);
    }
}

.responsive-sidebar {
    position: fixed;
    right: -450px;
    top: 0;
    width: 320px;
    height: 100%;
    background-color: #fff;
    z-index: 9999999;
    @include transition(.3s);

    &-inner {
        padding: 30px;
    }

    &-visible {
        right: 0;
    }

    &-close {
        background: transparent;
        border-color: #0054B8;
        border-width: 1.5px;
        padding: 0;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: #222;
        font-size: 16px;
        line-height: 27px;

        &:hover {
            background-color: #0054B8;
            color: #fff;
        }
    }
}

.mobile-menu {
    & .mean-nav {
        & ul {
            & li {
                & a {
                    font-weight: 500;
                }
            }
        }
    }
}

.resposive-sidebar-menu {
    max-height: 450px;
    overflow-y: auto;
}

.mean-container .mean-nav ul li a.mean-expand:hover,
.mean-container .mean-nav ul li a.mean-clicked {
    background-color: var(--clr-theme-1);
    border-color: var(--clr-theme-1) !important;
}

.vertical-middle {
    vertical-align: middle;
}

.has-header-border-bottom {
    border-bottom: 1px solid rgba(#fff, .2)
}

.header-sticky {
    @include transition(.3s);

    &.sticky {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 999;
        background-color: #fff;
        animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        display: block;
        -webkit-box-shadow: 0px 10px 20px 0px rgb(8 0 42 / 8%);
        -moz-box-shadow: 0px 10px 20px 0px #08002a14;
        box-shadow: 0px 10px 20px 0px rgb(8 0 42 / 8%);

        & .sasup-header {
            & ul {
                & li {
                    & a {
                        color: #222;
                    }

                    &:hover {
                        &>a {
                            color: #0054B8;
                            
                        }
                    }
                }
            }
        }
    }
}

.su-logo .su-sticky-log {
    display: none;
}

.sticky {
    & .sasup-transparent-btn {
        color: #222;
    }

    & .mobile-bar-control-white .line {
        background-color: #222;
    }

    & .sasup-theme-btn.style-white {
        background-color: var(--clr-theme-1);
        color: #fff;
    }
}

.sticky.header-sticky {
    .su-logo {
        .su-sticky-log {
            display: block;
        }

        .su-defaul-log {
            display: none;
        }
    }
}

.sasup-logo {
    max-width:150px;
}
/**
* Header style 4
*/
.sasup-header {
    &-logo {
        &-4 {
            max-width: 150px;
            line-height: 80px;
        }
    }

    &-nav-4 {
        & ul {
            & li {
                display: inline-block;
                list-style: none;

                & a {
                    font-family: $roboto;
                    font-weight: 500;
                    text-decoration: none;
                    font-size: 15px;
                    color: var(--clr-common-white);
                    display: inline-block;
                    padding: 27px 0;
                }

                &:hover>a {
                    color: var(--clr-theme-2);
                }
            }
        }
    }

    &-user {
        &-4 {
            font-family: $roboto;
            font-weight: 500;
            font-size: 15px;
            text-transform: capitalize;
            color: var(--clr-common-white);
            text-decoration: none;

            &:hover {
                color: var(--clr-theme-2);
            }

            & i {
                margin-right: 5px;
            }
        }
    }

    &-right-action-4 {
        display: flex;
        align-items: center;
        grid-column-gap: 20px;
    }
}

//update
.sticky .mobile-bar-control-white .line {
    background-color: #000;
}

.sticky.header-sticky {
    .mobile-bar-control-white .line {
        background-color: #000;
    }
}




//////////
.mm-menu {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li.has-droupdown {
            display: block;

            &:hover {
                >a {
                    padding-left: 3px;
                }
            }

            a {
                padding: 12px 0;
                display: block;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                font-size: 16px;
                text-transform: capitalize;
                color: #000 !important;
                font-weight: 600;
                text-transform: uppercase;
                position: relative;
                cursor: pointer;
                text-decoration: none;
            }

            a.active {
                padding-left: 3px;
                ;
            }
        }

        li.has-droupdown {
            >a {
                &:after {
                    position: absolute;
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-width: 2px 0 0 2px;
                    border-style: solid;
                    border-color: initial;
                    right: 16px;
                    top: 50%;
                    -webkit-transform: rotate(-45deg) translateY(-50%);
                    transform: rotate(-45deg) translateY(-50%);
                    -webkit-transform-origin: top;
                    transform-origin: top;
                    transition: all 0.3s ease-out;
                }
            }

            ul.sub-menu {
                padding-left: 0;
                list-style: none;

                li {
                    padding-left: 15px;

                    &:hover {
                        >a {
                            padding: 0 0 0 18px;

                            &:before {
                                background: #6d8397;
                                border-color: #6d8397;
                            }
                        }
                    }

                    a {
                        position: relative;
                        padding: 0 0 0 15px;
                        text-transform: capitalize;
                        font-size: 14px;
                        height: 0;
                        line-height: 46px;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.3s;
                        color: #000 !important;

                        &:before {
                            content: "";
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            border: 2px solid #6d8397;
                            border-radius: 50%;
                            visibility: hidden;
                            opacity: 0;
                            transition: all 0.3s;
                        }
                    }
                }
            }
        }

        li.has-droupdown.active {
            >a {
                &:after {
                    -webkit-transform: rotate(-135deg) translateY(-50%);
                    transform: rotate(-135deg) translateY(-50%);
                }
            }

            ul.sub-menu.active {
                li {
                    a {
                        height: 46px;
                        visibility: visible;
                        opacity: 1;

                        &:before {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.mm-menu ul li a {
    padding: 12px 0;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 16px;
    text-transform: capitalize;
    color: #000 !important;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    text-decoration: none;
}