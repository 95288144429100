/*
--------------------------------------------
26.faq
*/
@use '../abstracts/mixins' as *;
.sasup-accordion-item {
    margin-bottom: 10px;
    @include transition(.3s);
    &.sasup-active-item {
        box-shadow: 0px 30px 70px 0px #00052729;
        background-color: #fff;
    }
    & button {
        font-size: 16px;
        font-weight: 600;
        padding: 0 30px;
        border: 0;
        display: block;
        height: 60px;
        @include transition(.3s);
        width: 100%;
        background-color: transparent;
        color: #E97472;
        text-align: left;
        @include mq(xs) {
            height: inherit;
            padding-bottom: 13px;
            font-size: 15px;
            padding-top: 13px;
        }
        &.collapsed {
            background: #f2f3f8;
            color: #0a1426;
        }
    }
}
.sasup-accordion-desc {
    padding: 0 30px;
    & p {
        font-size: 15px;
        line-height: 24px;
        color: #535559;
        padding-bottom: 40px;
        margin-bottom: 0;
        @include mq(xs) {
            padding-bottom: 20px;
        }
    }
}

.sasup-accordion-item i.fa-minus {
    display: none;
}
.sasup-accordion-item.sasup-active-item i.fa-minus {
    display: none;
}
