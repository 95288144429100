/*
--------------------------------------------
27.breadcrumb
*/

@use '../abstracts/variables' as *;
@use '../abstracts/colors' as *;
@use '../abstracts/mixins' as *;

.breadcrumb-area {
    background: #fff;

    padding-top:80px;
    margin-bottom:40px;

    
    @include mq(lg) {
        padding-top:105px;
    }
    @include mq(md) {
        padding-top:105px;
    }
    @include mq(sm) {
        padding-top:100px;
    }
    @include mq(xs) {
        padding-top:75px;
    }
}