@import '~bootstrap/scss/bootstrap';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import '../../public/assets/css/backtotop.css';
//@import '../../public/assets/css/animate.min.css'; //commented out as we're not using it as of March 31 and its adding to our page speed load time
@import '../../public/assets/css/fontawesome-all.min.css';
//@import 'react-photo-view/dist/react-photo-view.css';
@import '../../public/assets/scss/main.scss';

.pointer-events-none {
  pointer-events: none;
}

.black-text {
  color:black !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline !important;
  }
}

#hero-email, #address {
  width:65%;

  @media screen and (max-width: 474px) {
    width:50%;
    
    
  }
}
.blog-quote {
  //italics with a hard border on the left
  border-left: 5px solid lightblue;
  padding-left: 10px;
  font-style: italic;
  margin-bottom:10px;
}
.pac-container {
    width: max-content !important;
    min-width:fit-content + 30px !important;

  }

  .ac-form-inputs {
    height:100%;
    display:inline;

  }

  .ac-form-inputs input {
      padding-left:12px !important;
      padding-right:12px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }

  //on focus of checkboxes add an effect (not just ac-forms)
  input:focus, button:focus {
    outline: none;
    //border-color: #4a90e2;
    box-shadow: 0 0 5px #4a90e2;
  }

  .no-focus {
    outline: none;
    box-shadow: none !important;
  }

  .no-focus:focus {
    border-color:transparent;
    //box-shadow: none !important;
  }

  .no-border {
    background:black;
  } 

  a:focus {
    //text-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

  button:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 5px transparent;
  }

  .monthly-tab-btn, .annual-tab-btn, .one-off-tab-btn {
    background: #fff;
    opacity:1;
    height: 50px;
    font-size: 16px;
    width:100px;
    color:black;
    border-radius: 0;
    //slightly darker than ddd
    border: 0.5px solid black;
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    border-radius:10px;
    //transition only to efffect background
    
    //.acive class
    &.active {
      font-weight:600;
      border: 0.5px solid black;
      opacity:1 !important;
      background: #494949;
      color: white;
    }

    //on hover keep the border
    &:hover {
      border: 0.5px solid black;
      opacity:1;
    } 
  }

  .annual-tab-btn {
    //no border radius on top right and bottom right
    border-radius: 0 10px 10px 0;
  }

  .one-off-tab-btn {
    //no border radius on top left and bottom left
    border-radius: 10px 0 0 10px;
  }

  .monthly-tab-btn {
    border-radius:0px;
    //make border left and right no border
    border-right: 0;
    border-left: 0;
    &.active {
      border-right: 0;
      border-left: 0;
    }

    &:hover {
      border-right: 0;
      border-left: 0;
    }
  }

  .custom-checkbox .form-check-input {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.25rem;
    border-radius: 0.25rem;
    border: 2px solid #007bff;
    cursor: pointer;
  }
  
  .custom-checkbox .form-check-input:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .custom-checkbox .form-check-label {
    padding-left: 0.5rem;
    cursor: pointer;
  }

  .address-bar {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.actions {
    margin-left: auto;
}

.custom-domain-alert p {
    margin-left: 10px;
}

.domain-info-modal {
  z-index: 1060 !important;
}

.modal-backdrop.show:nth-of-type(2) {
  z-index: 1055 !important;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.editor-container {
  min-height: 100vh;
}

@keyframes fadeOutRed {
  0% {
    background-color: transparent;
    opacity: 1;
  }
  100% {
    background-color: #ff0000;
    opacity: 0;
  }
}

.fade-out-red {
  animation: fadeOutRed 300ms ease-in-out forwards;
}

.fade-out-red * {
  transition: all 300ms ease-in-out;
}

.fade-out-red input,
.fade-out-red textarea {
  background-color: transparent !important;
  border-color: transparent !important;
  color: white !important;
}

.top-30 {
  top: 30% !important;
}

.price-matrix-container {
  font-family: Arial, sans-serif;
}

.price-matrix table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.price-matrix th {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  vertical-align: top;
}

.price-matrix td {
  padding: 15px;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}

.price-matrix tr td:first-child {
  font-weight: bold;
}

.plan-name {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.plan-price {
  font-size: 2em;
  font-weight: bold;
  color: #6c5ce7;
}

.plan-period {
  font-size: 0.9em;
  color: #6c757d;
  margin-bottom: 15px;
}

.try-free-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6c5ce7;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.try-free-btn:hover {
  background-color: #5b4bc7;
}

.section-header td {
  font-weight: bold;
  background-color: #f8f9fa;
  color: #6c5ce7;
}

.feature-yes {
  color: #28a745;
}

.feature-no {
  color: #dc3545;
}

.plan-tag {
  background-color: #6c5ce7;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  margin-bottom: 10px;
  display: inline-block;
}

.pricing-tabs-wrapper {
  transition: all 0.3s ease;
}

.pricing-tabs-wrapper.sticky {
  padding: 10px 0;
}


.blog-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.section-title {
  font-size: 2rem;
  font-weight: 600;
  color: #34495e;
  margin: 2rem 0 1rem;
}

.blog-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

.word-section {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 15px;
  margin: 2rem 0;
}

.word-card {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  margin-bottom: 1.5rem;
}

.word-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.word-title .number {
  color: #08b6ef;
  margin-right: 0.5rem;
}

.word-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  font-style: italic;
}

.word-description a {
  color: #08b6ef;
  text-decoration: none;
  transition: color 0.2s;
}

.word-description a:hover {
  color: #0696c7;
}

.back-to-blog {
  display: inline-flex;
  align-items: center;
  color: #555;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
}

.back-to-blog i {
  margin-right: 0.5rem;
}

.back-to-blog:hover {
  color: #08b6ef;
}

.term-item {
  padding: 1rem;
  background: #f8fafc;
  border-radius: 0.375rem;
}

.word-title sup {
  margin-left: 0.5rem;
}