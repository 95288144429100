
.word-card {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  margin-bottom: 2rem;
  
  .word-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    
    sup {
      font-size: 0.75rem;
      color: #0054B8;
      font-weight: normal;
      margin-left: 0.5rem;
    }
  }

  .related-terms {
    margin-top: 2rem;
    border-top: 1px solid #e9ecef;
    padding-top: 2rem;

    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 1.5rem;
    }

    &-card {
      background: #f8f9fa;
      border-radius: 12px;
      padding: 1.5rem;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.06);
      }

      h4 {
        color: #0054B8;
        font-weight: 600;
        margin-bottom: 0.75rem;
      }

      p {
        color: #555;
        font-size: 0.95rem;
        line-height: 1.6;
      }
    }
  }

  .example-text {
    color: #0054B8;
    font-style: italic;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 1rem 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.5rem;

      &:before {
        content: "✓";
        color: #22c55e; // text-green-500
        margin-right: 1rem;
        font-weight: bold;
      }
    }
  }

  .btn-primary, 
  a[class*="bg-blue-500"] {
    background-color: #0054B8;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #003d85;
    }
  }
} 

