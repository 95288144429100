/*
--------------------------------------------
23.footer
*/
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;

.widget-text {
    font-size: 15px;
    color: #686b6f;
    line-height: 22px;
    margin-bottom: 25px;
}

.footer-widget {
    &-3 {
        &.space-left-0 {
            @include mq(xl) {
                padding-left: 0;
            }

            @include mq(lg) {
                padding-left: 0;
            }

            @include mq(md) {
                padding-left: 0;
            }

            @include mq(sm) {
                padding-left: 0;
            }

            @include mq(xs) {
                padding-left: 0;
            }
        }
    }

    &-4 {
        & ul {
            & li {
                & a {
                    color: #adb0b6;
                }
            }
        }
    }

    & h5 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 24px;
        color: #0a1426;

        &.font-semi-bold {
            font-weight: 600;
        }

        &.font-medium {
            font-weight: 500;
        }
    }

    & li {
        list-style: none;

        & a {
            text-decoration: none;
            font-size: 15px;
            color: #686b6f;

            &:hover {
                color: var(--clr-theme-1);
            }
        }
    }

    & ul {
        & li:not(:last-child) {
            list-style: none;
            margin-bottom: 12px;

            &:hover {
                color: var(--clr-theme-1);
            }
        }
    }

    &-3 {
        & ul {
            & li {
                & a {
                    color: #c0c1cb;
                }
            }
        }
    }
}

.footer-2 {
    & .footer-widget {
        & li {
            &:hover {
                & a {
                    color: #0054B8;
                }
            }
        }
    }
}

.has-border-bottom {
    border-bottom: 1px solid #dedfe4;
}

.copyright-text {
    font-size: 15px;
    color: #686b6f;
    margin-bottom: 0;

    @include mq(md) {
        font-size: 13px;
    }

    @include mq(sm) {
        margin-bottom: 20px;
    }

    @include mq(xs) {
        margin-bottom: 20px;
    }

    & a {
        text-decoration: none;
        color: var(--clr-theme-1);
    }
}

.footer-social {
    & a {
        width: 34px;
        height: 34px;
        border-radius: 4px;
        color: #fff;
        line-height: 34px;
        margin-left: 10px;
        display: inline-block;
        text-align: center;
    }
}

.has-border-bottom-3 {
    border-bottom: 1px solid #252e4a;
}

.social-follow-space {
    & a {
        @include mq(xs) {
            margin-right: -2px;
        }
    }
}

.footer-negative-space {
    margin-top: -8px;
}


// footer extra 1
.footer-black-list {
    & ul {
        & li {
            overflow: hidden;

            & span {
                line-height: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }
    }

    & .icon {
        float: left;
        margin-right: 14px;

        & i {
            vertical-align: middle;
            color: #ff4456;
        }
    }

    & .content {
        overflow: hidden;

        & span {
            color: #ffffff;
            font-size: 15px;
        }
    }
}

.newsletter-content-info {
    & p {
        color: #ffffff;
        opacity: .7;
        font-size: 14px;
        line-height: 22px;
    }
}

.footer-widget-desc-info {
    & p {
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
        opacity: .7;
        margin-bottom: 0;
    }
}

.footer-social-white {
    & a {
        width: 30px;
        height: 30px;
        text-align: center;
        border: 2px solid #22458b;
        line-height: 26px;
        border-radius: 4px;
        margin-right: 6px;
        display: inline-block;
        font-size: 12px;
        color: #fff;

        &:hover {
            border-color: transparent;
            color: var(--clr-theme-1);
            background-color: #fff;
        }
    }
}



/***
* Footer style 4
*/
.sasup {
    &-logo-4 {
        max-width: 116.69px;
    }

    &-footer {
        &-widget-social-link-4 {
            & .title {
                font-family: $roboto;
                font-weight: 700;
                margin-bottom: 0;
                font-size: 15px;
                margin-bottom: 15px;
                color: var(--clr-common-white);
            }

            & a {
                width: 40px;
                height: 40px;
                border: 2px solid rgba(255, 255, 255, 0.06);
                border-radius: 100px;
                display: inline-block;
                text-align: center;
                line-height: 38px;
                margin-right: 5px;
                color: var(--clr-body-gray-3);
                font-size: 14px;

                &:hover {
                    background-color: var(--clr-theme-3);
                    color: var(--clr-common-white);
                    border-color: transparent;
                }
            }
        }

        &-widget {
            &-title-4 {
                font-family: $space;
                font-weight: 700;
                font-size: 22px;
                letter-spacing: -0.04em;
                color: var(--clr-common-white);
            }

            &-4 {
                & ul {
                    & li {
                        list-style: none;
                        margin-bottom: 10px;

                        & a {
                            display: block;
                            font-family: 'Roboto';
                            font-weight: 400;
                            text-decoration: none;
                            font-size: 16px;
                            color: var(--clr-body-gray-4);

                            &:hover {
                                color: var(--clr-theme-3);
                            }
                        }
                    }
                }

                & p {
                    font-family: $roboto;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--clr-common-white);
                    opacity: 0.8;
                }
            }

            &-contact-4 {
                & a {
                    display: block;
                    text-decoration: none;
                    font-family: $roboto;
                    font-weight: 400;
                    font-size: 15px;
                    color: var(--clr-common-white);
                    margin-bottom: 12px;

                    & svg {
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}

.sasup-footer-4-space {
    margin-top: -190px;
}

.sasup-newspaper-form-4 {
    & p {
        font-size: 14px;
        line-height: 20px;
    }

    & form {
        position: relative;
        border-radius: 6px;
        overflow: hidden;
    }

    & input[type="email"] {
        width: 100%;
        height: 50px;
        background: var(--clr-common-white);
        border-radius: 6px;
        border: 0;
        padding: 0 20px;
        font-family: $roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--clr-body-gray-5);

        &::placeholder {
            font-family: $roboto;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: var(--clr-body-gray-5);
        }
    }

    & button[type="submit"],
    & input[type="submit"] {
        height: 50px;
        padding: 0 20px;
        top: 6864px;
        background: var(--clr-theme-3);
        color: var(--clr-common-white);
        position: absolute;
        right: 0;
        top: 0;
        border: 0;
    }
}

.sasup-copyright {
    &-menu-link-4 {
        @include mq(xs) {
            text-align: center !important;
        }

        & a {
            font-family: $space;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: var(--clr-body-gray-6);
            text-decoration: none;
            margin-left: 36px;
            display: inline-block;

            @include mq(xs) {
                margin-left: 8px;
            }

            &:hover {
                color: var(--clr-theme-3);
            }
        }
    }

    &-text-4 {
        font-family: $space;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: var(--clr-body-gray-6);
        margin-bottom: 0;

        @include mq(xs) {
            margin-bottom: 20px;
            text-align: center;
        }

        & a {
            color: var(--clr-common-white);
            text-decoration: none;

            &:hover {
                color: var(--clr-theme-3);
            }
        }
    }
}

.sasup-has-copyright-border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    ;
}

.sasup-footer-widget-4.pl-30.sasup-footer-widget-4-2 {
    @include mq(lg) {
        padding-left: 0;
    }

    @include mq(md) {
        padding-left: 0;
    }

    @include mq(xs) {
        padding-left: 0;
    }
}

.sasup-footer-widget-4.pl-100.sasup-footer-widget-4-2 {
    @include mq(lg) {
        padding-left: 0;
    }

    @include mq(md) {
        padding-left: 0;
    }

    @include mq(xs) {
        padding-left: 0;
    }
}

.sasup-service-box-4.pl-65.p-rel {
    @include mq(md) {
        padding-left: 40px;
    }

    @include mq(xs) {
        padding-left: 0px;
    }
}

.sasup-service-box-4.pl-55.p-rel {
    @include mq(md) {
        padding-left: 20px;
    }

    @include mq(xs) {
        padding-left: 0px;
    }
}

.sasup-service-box-4 {

    @include mq(xs) {
        margin-bottom: 40px;
    }
}

.newsletter-form-2 {
    position: relative;

    & button[type="submit"] {
        height: 50px;
        padding: 0 15px;
        background: #E97472;
        color: #fff;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 0;
        position: absolute;
        font-size: 15px;
        right: 0;
        top: 0;
        font-weight: 500;
    }
}