/*
--------------------------------------------
12.cta
*/
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;

.cta-title {
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    color: #fff;
    margin-bottom: 10px;

    @include mq(xs) {
        font-size: 29px;
        line-height: 1.2;
    }

    @include mq(sm) {
        font-size: 38px;
        font-weight: 700;
        line-height: 1.2;
    }

    @include mq(md) {
        font-size: 36px;
        line-height: 1.2;
    }

    @include mq(xl) {
        font-size: 41px;
        line-height: 1.2;
    }

    @include mq(lg) {
        font-size: 40px;
        line-height: 1.2;
    }

    & br {
        @include mq(md) {
            display: none;
        }

        @include mq(sm) {
            display: none;
        }
    }
}

.cta-desc {
    font-size: 16px;
    color: #fff;

    @include mq(sm) {
        line-height: 1.3;
        margin-bottom: 24px;
    }
}

.sasup-white-btn {
    text-decoration: none;
    font-size: 15px;
    color: #0a1426;
    background: #fff;
    display: inline-block;
    font-weight: 500;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    padding: 0 22px;

    &:hover {
        background-color: #0054B8;
        color: #fff;
    }
}

.cta-3-spacing {
    @include mq(xl) {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    @include mq(lg) {
        padding-top: 60px;
        padding-bottom: 45px;
    }

    @include mq(md) {
        padding-top: 90px;
        padding-bottom: 75px;
    }

    @include mq(md) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
}

.cta-subtitle-1 {
    /*sublile dropshadow to help with light background */
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin-bottom: 12px;
    color: #ffffff;
    line-height: 1;
}

.cta-title-1 {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    font-size: 44px;
    font-weight: 700;
    line-height: 54px;
    color: #f8f8f9;
    display: block;
    margin-bottom: 0;

    @include mq(lg) {
        font-size: 35px;
        line-height: 1.4;
    }

    @include mq(sm) {
        font-size: 35px;
    }

    @include mq(xs) {
        font-size: 23px;
        line-height: 1.6;
    }
}

.bg-default {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.cta-border-btn,
.cta-solid-btn {
    display: inline-block;
    height: 45px;
    line-height: 42px;
    text-decoration: none;
    border: 2px solid #6e59af;
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    padding: 0 50px;

    border-color: transparent;
    background-color: #0054B8;
    color:#fff;   

    @include mq(xs) {
        padding: 0 20px;
    }

    &:hover {
        background-color: #0a58ca;
        color: #fff;
        border-color: transparent;
    }
}

.cta-1-space {
    @include mq(sm) {
        padding: 90px 0;
    }

    @include mq(xs) {
        padding: 90px 0;
    }
}

/**
* cta style 04
*/
.sasup-cta {
    &-box-4 {
        border-radius: 10px;
        overflow: hidden;
    }

    &-title-4 {
        font-family: $space;
        font-weight: 700;
        font-size: 50px;
        line-height: 54px;
        margin-bottom: 12px;
        letter-spacing: -0.04em;
        color: var(--clr-common-white);

        @include mq(xs) {
            font-size: 36px;
            line-height: 1.3;
        }
    }

    &-subtitle-4 {
        font-family: $space;
        font-weight: 300;
        font-size: 24px;
        letter-spacing: -0.04em;
        color: var(--clr-common-white);
        display: block;
    }
}

.cta-bg-color {
    background-color: #663ae9;
}

.cta-bg-default {
    background-color: black;
}