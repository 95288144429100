/*
--------------------------------------------
25.about
*/
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;

.about-img-group {
    position: relative;

    & .about-img-1 {
        box-shadow: 0px 30px 70px 0px #00153e1a;
        display: inline-block;
        z-index: 3;
        position: relative;
        -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
        -moz-animation: hero-thumb-animation 2s linear infinite alternate;
        -o-animation: hero-thumb-animation 2s linear infinite alternate;
        animation: hero-thumb-animation 2s linear infinite alternate;

        & .about-img-inner {
            z-index: 3;
            background-color: #fff;
            position: relative;
        }
    }

    & .about-img-2 {
        position: absolute;
        left: -30px;
        z-index: 9;
        -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
        -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
        -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
        animation: hero-thumb-sm-animation 4s linear infinite alternate;
        bottom: -40px;
        box-shadow: 0px 30px 70px 0px #00153e1a;

        @include mq(xs) {
            left: 17px;
        }

        & .about-img-inner {
            z-index: 9;
            background-color: #fff;
            position: relative;
        }
    }

    & .about-img-3 {
        position: absolute;
        left: 15px;
        bottom: -40px;
    }

    & .about-img-4 {
        position: absolute;
        left: -5px;
        bottom: 155px;

        @include mq(xs) {
            left: 16px;
        }
    }

    & .about-img-5 {
        position: absolute;
        left: -42px;
        top: 42px;

        @include mq(xs) {
            left: 7px;
        }
    }
}

.about {
    &-subtitle-2 {
        font-size: 16px;
        font-weight: 500;
        color: #E97472;
        display: block;
        margin-bottom: 10px;
    }

    &-title-2 {
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 32px;
        color: #0a1426;

        @include mq(xs) {
            font-size: 25px;
        }

        @include mq(md) {
            font-size: 27px;
        }

        & br {
            @include mq(xs) {
                display: none;
            }
        }
    }

    &-content-2 {
        line-height: 26px;
        color: #535559;
    }
}

.about-img-group-2 {
    display: inline-block;
    position: relative;

    & .about-img-1 {
        -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
        -moz-animation: hero-thumb-animation 2s linear infinite alternate;
        -o-animation: hero-thumb-animation 2s linear infinite alternate;
        animation: hero-thumb-animation 2s linear infinite alternate;
        box-shadow: 0px 30px 70px 0px #00153e1a;
        z-index: 3;
        background-color: #fff;
        position: relative;
    }

    & .about-img-2 {
        bottom: -81px;
        position: absolute;
        left: -50px;
        -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
        -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
        -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
        animation: hero-thumb-sm-animation 4s linear infinite alternate;
        box-shadow: -11.722px -27.615px 60px 0px #00153e1a;
        z-index: 9;
        background-color: #fff;
    }

    & .about-img-3 {
        position: absolute;
        top: -30px;
        right: 0;
    }

    & .about-img-4 {
        position: absolute;
        left: -85px;
        bottom: 48px;

        @include mq(xs) {
            left: -61px;
        }

        & img {
            -webkit-animation: hero-triangle-1 6s linear infinite;
            -moz-animation: hero-triangle-1 6s linear infinite;
            -o-animation: hero-triangle-1 6s linear infinite;
            animation: hero-triangle-1 6s linear infinite;
        }
    }

    & .about-img-5 {
        position: absolute;
        top: 70px;
        left: -47px;

        @include mq(xs) {
            left: -4px;
        }

        & img {
            -webkit-animation: services-triangle 7s linear infinite alternate;
            -moz-animation: services-triangle 7s linear infinite alternate;
            -o-animation: services-triangle 7s linear infinite alternate;
            animation: services-triangle 7s linear infinite alternate;
        }
    }
}

.mb-37 {
    margin-bottom: 37px;
}

.mb-215 {
    margin-bottom: 215px;
}

.about-left-2 {
    @include mq(xs) {
        padding-right: 0;
    }
}

.about-img-group {
    & .about-img-1 {
        & .about-img-inner {
            & img {
                @include mq(xs) {
                    max-width: 235px !important;
                }

                @include mq(sm) {
                    max-width: 210px;
                }
            }
        }
    }

    & .about-img-2 {
        & img {
            @include mq(xs) {
                max-width: 185px !important;
            }
        }
    }
}

.about-right-2 {
    @include mq(xs) {
        padding-left: 0;
    }

    @include mq(xxl) {
        padding-left: 0;
    }

    @include mq(xl) {
        padding-left: 0;
    }

    @include mq(lg) {
        padding-left: 0;
    }

    @include mq(md) {
        padding-left: 0;
    }
}

.about-img-group-2 {
    & .about-img-inner {
        & img {
            @include mq(xs) {
                max-width: 230px !important;
            }
        }
    }
}

.about-left-2-space {
    @include mq(xs) {
        margin-bottom: 0;
    }

    @include mq(xxl) {
        padding-right: 0;
    }
}

.about-left-2 {
    @include mq(lg) {
        padding-right: 40px;
    }

    @include mq(md) {
        padding-right: 20px;
    }
}

.about-content-2 {
    @include mq(md) {
        font-size: 15px;
    }
}

.about-content {
    position: relative;
    z-index: 9;
}

.s-title-about {
    @include mq(md) {
        font-size: 33px;
    }
}

.best-bottom-space {
    @include mq(xs) {
        margin-bottom: 90px;
    }
}

/**
* about style 4
*/
.sasup-about {
    &-dashbord {
        &-left-4 {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            grid-column-gap: 25px;
            ;

            &>* {
                flex: 0 0 auto;

                @include mq(xs) {
                    max-width: 100%;
                }
            }
        }

        &-avatar {
            &-4 {
                max-width: 115px;

                & img {
                    -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                }

                &-2 {
                    & img {
                        -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
                        -moz-animation: hero-thumb-animation 2s linear infinite alternate;
                        -o-animation: hero-thumb-animation 2s linear infinite alternate;
                        animation: hero-thumb-animation 2s linear infinite alternate;
                    }
                }
            }
        }

        &-right-4 {
            & p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.sasup-sm-project-template-box {
    &-4 {
        overflow: hidden;
        background: var(--clr-common-white);
        box-shadow: 0px 1px 2px rgba(19, 15, 64, 0.1);
        border-radius: 6px;
        padding: 15px 25px;
        padding-right: 35px;
    }

    &-img-4 {
        max-width: 36px;
        position: relative;
        float: left;
        margin-right: 20px;
    }

    &-icon-4 {
        position: absolute;
        right: -2px;
        bottom: -1px;
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background: var(--clr-common-white);
        border-radius: 50%;
        box-shadow: 0px 6px 10px rgba(19, 15, 64, 0.1);
    }

    &-content-4 {
        overflow: hidden;

        & p {
            font-family: $roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            margin-bottom: 0;
            line-height: 20px;
            color: var(--clr-body-gray);

            & span {
                color: var(--clr-common-black-2);
                font-weight: 500;
            }
        }
    }
}

.sasup-about-dashbord-service {
    &-single-4 {
        display: flex;
        align-items: center;
        grid-column-gap: 13px;
        position: relative;

        @include mq(md) {
            margin-bottom: 20px;
        }

        @include mq(lg) {
            margin-bottom: 20px;
        }

        @include mq(xs) {
            margin-bottom: 20px;
        }

        &>* {
            position: relative;
            z-index: 3;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 74px;
            height: 74px;
            border-radius: 100px;
            @include transition(.3s);
            content: "";
            box-shadow: 0px 2px 4px rgba(19, 15, 64, 0.1);
            background-color: var(--clr-common-white);
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }
    }

    &-icon-4 {
        width: 74px;
        text-align: center;
        line-height: 74px;
        height: 74px;
        border-radius: 100px;

        & img {
            max-width: 32.4px;
        }
    }

    &-content-4 {
        margin-top: 5px;
    }

    &-title-4 {
        font-family: $roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--clr-body-gray);
        margin-bottom: 0;
    }

    &-link-4 {
        font-family: $roboto;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        text-decoration: none;

        & i {
            vertical-align: middle;
            margin-left: 5px;
        }

        &:hover {
            color: var(--clr-theme-1);
        }

        color: var(--clr-common-black-2);

    }
}

.sasup-about-dashbord-service-wrap-4 {
    border: 1px solid rgba(106, 85, 255, 0.1);
    border-radius: 100px;
    padding: 8px;
}

.sasup-about-dashbord-shape-wrap {
    &>* {
        position: absolute;
    }

    & .shape-1 {
        top: 80px;
        left: 0;

        & img {
            -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
            -moz-animation: hero-thumb-animation 2s linear infinite alternate;
            -o-animation: hero-thumb-animation 2s linear infinite alternate;
            animation: hero-thumb-animation 2s linear infinite alternate;
        }

        @include mq(xs) {
            display: none;
        }
    }

    & .shape-2 {
        top: 20%;
        left: 15.5%;

        & img {
            -webkit-animation: services-triangle 7s linear infinite alternate;
            -moz-animation: services-triangle 7s linear infinite alternate;
            -o-animation: services-triangle 7s linear infinite alternate;
            animation: services-triangle 7s linear infinite alternate;
        }

        @include mq(xs) {
            display: none;
        }
    }

    & .shape-3 {
        right: 9.8%;
        top: 42.5%;

        & img {
            -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
            -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
            -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
            animation: hero-thumb-sm-animation 4s linear infinite alternate;
        }

        @include mq(xs) {
            display: none;
        }
    }

    & .shape-4 {
        top: 0;
        right: 4%;
    }

    & .shape-5 {
        right: 0;
        bottom: 15.5%;

        & img {
            -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
            -moz-animation: hero-thumb-animation 2s linear infinite alternate;
            -o-animation: hero-thumb-animation 2s linear infinite alternate;
            animation: hero-thumb-animation 2s linear infinite alternate;
        }
    }
}

// singup style

.signin-area-wrapper {
    display: flex;
}

.sign-up-wrapper {
    display: flex;
}

.signup-wrapper {
    input {
        width: 100%;
        height: 55px;
        background: #f5f5f5;
        border: 0;
        border-radius: 4px;
        margin-bottom: 30px;
        display: flex;
        padding: 10px 20px;
        outline: none;
        color: #0a1426;
        font-size: 16px;
    }
}

.signup-input-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
    .signup-input-wrapper {
        display: inherit;
    }
}



.signup-input-wrapper {
    input {
        width: 100%;
        height: 55px;
        background: #f5f5f5;
        border: 0;
        border-radius: 4px;
        margin-bottom: 30px;
        display: flex;
        padding: 10px 20px;
        outline: none;
        color: #0a1426;
        font-size: 16px;
    }
}

.sign-check {
    a {
        color: #0054B8;
        font-weight: 700;
        text-decoration: underline;

        &:hover {
            color: #000;
        }
    }
}

.signup-box {
    background: #f5f5f5;
    padding: 45px 30px;
}

.signup-thumb {
    img {
        max-width: 100%;
    }
}

.signup-form-wrapper {
    padding: 45px 30px;
    background: #fff;
}

.signup-action {
    margin-bottom: 25px;
}

.sing-buttom {
    background: #0054B8;
    text-align: center;
    line-height: 55px;
    color: #000;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    transition: 0.3s;

    &:hover {
        color: #000;
        background: #6A55FF;
    }
}

button.sing-btn {
    border: none;
    background: #0054B8;

    &:hover {
        color: #fff;
    }
}

.registered.wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.forget-password a {
    text-decoration: none;

    &:hover {
        color: #000;
    }
}

.not-register a {
    &:hover {
        color: #000;
    }
}

.acount-login {
    margin-bottom: 30px;

    a {
        font-weight: 600;
        color: #0054B8;
        margin-left: 5px;
        text-decoration: underline;

        &:hover {
            color: #000;
        }
    }
}

.sign-social {
    position: relative;
    z-index: 2;
    margin-bottom: 25px;

    span {
        background: #fff;
        display: inline-block;
        padding: 0 10px;
    }

    &:before {
        position: absolute;
        height: 1px;
        background: #E8ECF0;
        content: "";
        width: 100%;
        top: 15px;
        left: 0;
        z-index: -1;
    }
}

.sign-social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 19px;
}

.signup-text {
    h3 {
        font-size: 28px;
        margin-bottom: 106px;
    }
}

.signup-message {
    img {
        max-width: 100%;
        margin-bottom: 5px;
    }
}

.not-register {
    a {
        font-weight: 600;
        color: #0054B8;
        text-decoration: revert;
        margin-left: 5px;
    }
}

.sign-gmail {
    border: 1px solid #E8ECF0;
    padding: 10px 20px;
    border-radius: 4px;

    a {
        font-weight: 600;
        text-decoration: none;

        &:hover {
            color: #000;
            text-decoration: none;
        }
    }

    svg {
        height: 20px;
        margin-right: 5px;
        transform: translateY(-2px);
    }
}

.sign-facebook {
    border: 1px solid #E8ECF0;
    padding: 10px 20px;
    border-radius: 4px;

    a {
        font-weight: 600;
        text-decoration: none;

        &:hover {
            color: #000;
            text-decoration: none;
        }
    }

    svg {
        height: 20px;
        margin-right: 5px;
        transform: translateY(-2px);
    }
}

.signin-area {
    position: fixed;
    z-index: 1008;
    top: 60px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: none;
    max-width: 900px;
}

.login__register-now p a {
    color: #2467ec;
    font-weight: 600;
    text-decoration: none;
}

.signup-checkbo {
    margin-right: 5px;
}

.signin-area.open {
    display: inline-block;
}

.signin-area-wrapper> :nth-child(2) {
    flex: 0 0 calc(100% - 360px);
}

@media (min-width: 992px) and (max-width: 1199px) {
    .signin-area-wrapper>*:nth-child(1) {
        flex: 0 0 360px;
    }

    .signin-area-wrapper> :nth-child(2) {
        flex: 0 0 calc(100% - 360px);
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .signin-area-wrapper {
        max-width: 750px;
        max-height: 530px;
        transform: none;
        overflow: auto;
        overflow-y: auto;
        display: inline-block;
    }

    .signin-area.open {
        display: flex;
        justify-content: center;
    }

    .signin-area-wrapper>* {
        flex: 0 0 100% !important;
    }
}

@media (max-width: 767px) {
    .signin-area-wrapper {
        max-width: 750px;
        max-height: 530px;
        transform: none;
        overflow: auto;
        overflow-y: auto;
        display: inline-block;
    }

    .signin-area.open {
        display: flex;
        justify-content: center;
    }

    .signin-area-wrapper>* {
        flex: 0 0 100% !important;
    }
}

// signup
.signup-area {
    position: fixed;
    z-index: 99998;
    top: 60px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: none;
    max-width: 900px;
}

.signup-area.open {
    display: inline-block;
}

.sign-up-wrapper> :nth-child(2) {
    flex: 0 0 calc(100% - 360px);
}

@media (min-width: 992px) and (max-width: 1199px) {
    .sign-up-wrapper>*:nth-child(1) {
        flex: 0 0 360px;
    }

    .sign-up-wrapper> :nth-child(2) {
        flex: 0 0 calc(100% - 360px);
    }
}

.bd-brands {
    max-height: 120px;
}

.signUp-error-style {
    margin-top: -16px;
}

// content-error style
.content-error-area {
    border-top: 1px solid #edeef2;
}

.error-thumb {
    img {
        margin-bottom: 40px;
        max-width: 100%;
    }
}

.error-btn {
    margin-top: 35px;
}

.video-link-img::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 150px;
    background-image: -webkit-linear-gradient(90deg, rgba(10, 20, 38, 0.8) 0%, rgba(10, 20, 38, 0.8) 0%, rgba(10, 20, 38, 0) 100%);
    content: "";
}