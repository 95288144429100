/*
--------------------------------------------
17.subscribe
*/
@use '../abstracts/mixins' as *;
.subscribe-form {
    
}
.service-list-2 {
    & span {
        font-size: 14px;
        display: inline-block;
        margin: 0 17px;
        font-weight: 500;
        & i {
            margin-right: 7px;
        }
    }
}
.subscribe-s-title {
    & .s-subtitle {
        font-size: 14px;
        font-weight: 500;
        display: block;
        color: #E97472;
        margin-bottom: 14px;
    }
    & .s-title {
        font-size: 40px;
        font-weight: 700;
        color: #0a1426;
        @include mq(xs) {
            font-size: 31px;
        }
        & br {
            @include mq(xs) {
                display: none;
            }
        }
    }
}

.subscribe-left-space-2 {
    @include mq(xs) {
        padding-top: 0;
        margin-bottom: 30px;
    }
    @include mq(md) {
        padding-top: 0;
        margin-bottom: 30px;
    }
}
.subscribe-area-space-2 {
    @include mq(xs) {
        padding: 100px 0;
    }
}