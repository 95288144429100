/*
--------------------------------------------
13.development_flowchart
*/
@use '../abstracts/mixins' as *;
.sasup-tab-control {
    display: block;
    width: 100%;
    & button {
        border: 0;
        display: block;
        padding: 0;
        height: 76px;
        padding: 0 20px;
        width: 100%;
        display: flex;
        border-radius: 4px;
        align-items: center;
        text-align: left;
        background: transparent;
        @include transition(.3s);
        &.active {
            background-color: #fff;
            box-shadow: 0px 20px 70px 0px rgba(#000527, .14),-3px 0px 0px 0px rgba(#2f74ff, 0.004);
            position: relative;
            @include transition(.3s);
            color: #0054B8;
            & i {
                color: #0054B8;
            }
            &::after {
                position: absolute;
                left: -3px;
                top: 0;
                content: "";
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                height: 100%;
                width: 3px;
                background-color: #2d6ff4;
            }
        }
        & i {
            color: #0a1426;
            font-size: 22px;
            font-weight: 400;
            margin-right: 10px;
        }
        & span {
            font-size: 14px;
            font-weight: 500;
        }
        @include mq(xs) {
            height: 60px;
        }
    }
}
.dashbord-tab-controls {
    max-width: 250px;
    @include mq(xs) {
        padding-top: 0;
    }
    @include mq(md) {
        max-width: 100%;
        padding-top: 20px;
    }
    @include mq(sm) {
        max-width: 100%;
        padding-top: 20px;
    }
    & .nav {
        @include mq(md) {
            flex-direction: row;
        }
        @include mq(sm) {
            flex-direction: row;
        }
        & > * {
            @include mq(md) {
                flex: 0 0 50%;
            }
            @include mq(sm) {
                flex: 0 0 50%;
            }
            & button {
                @include mq(md) {
                    height: 56px;
                }
                @include mq(sm) {
                    height: 56px;
                }
            }
        }
    }
}
.sasup-single-tab-dashbord {
    margin-left: -99px;
    @include mq(lg) {
        margin-left: 0;
    }
    @include mq(sm) {
        margin-left: 0;
    }
    @include mq(xs) {
        margin-left: 0;
    }
    & img {
        max-width: inherit;
        -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
        -moz-animation: hero-thumb-animation 2s linear infinite alternate;
        -o-animation: hero-thumb-animation 2s linear infinite alternate;
        animation: hero-thumb-animation 2s linear infinite alternate;
        @include mq(lg) {
            max-width: 550px;
        }
        @include mq(md) {
            max-width: calc(100% + 78px);
        }
        @include mq(sm) {
            max-width: 100%;
        }
        @include mq(xs) {
            max-width: 100%;
        }
    }
}

.sasup-service-img-wrap {
    min-height: 530px;
    position: relative;
    @include mq(lg) {
        min-height: 430px;
    }
    @include mq(xs) {
        min-height: 319px;
    }
    & .sasup-single-service-thumb {
        overflow: hidden;
        position: absolute;
        box-shadow: 0px 20px 50px 0px rgba(#000527, 0.1);
        &:nth-child(1) {
            left: 0;
            top: 50px;
            z-index: 1;
        }
        &:nth-child(2) {
            right: 0;
            top: 0;
            z-index: 3;
        }
        &:nth-child(3) {
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            z-index: 2;
        }
    }
}
.sasup-single-tab-dashbord {
    & img {
        @include mq(xl) {
            max-width: calc(100% + 31px);
        }
    }
}
.dashbord-tab-wrap {
    @include mq(lg) {
        padding: 50px 40px;
    }
    @include mq(sm) {
        padding: 40px 20px;
    }
    @include mq(xs) {
        padding: 50px 20px;
    }
}

.dashbord-tab-control-space-3 {
    @include mq(lg) {
        padding-top: 15px;
    }
}
.service-3-space {
    padding-bottom: 80px;
    padding-top: 80px;
}