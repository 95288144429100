/*
--------------------------------------------
29.widget
*/
@use '../abstracts/mixins' as *;

.widget-shadow-default {
    box-shadow: 0px 20px 40px 0px #0005271a;
}

.right-widget-area {
    padding: 45px 40px;

    &.has-space {
        padding-top: 50px;
    }

    @include mq(xs) {
        padding: 36px 27px;
    }
}

.widget {
    &-title {
        font-weight: 700;
        color: #0a1426;
        margin-bottom: 30px;
        font-size: 20px;
    }

    & li {
        font-size: 15px;
        font-weight: 500;
        color: #535559;
        list-style-type: none;

        &:not(:last-child) {
            padding-bottom: 15px;
            margin-bottom: 13px;
            border-bottom: 1px solid #e8e8e9;
        }

        &:hover {
            color: var(--clr-theme-1);
        }

        & a {
            text-decoration: none;
            display: block;

            & i {
                margin-right: 14px;
                color: var(--clr-theme-1);
            }
        }
    }
}

.form-touch-service {
    &-input {
        & input {
            height: 50px;
            border: 0;
            font-size: 14px;
            border-radius: 4px;
            color: #707276;
            padding: 0 23px;
            background: #eeeff4;
            width: 100%;
            margin-bottom: 10px;

            &:focus {
                border: 2px solid var(--clr-theme-1);
            }
        }
    }
}

.widget-contact {
    &-title {
        font-size: 14px;
        margin-bottom: 0;
        color: #0a1426;
    }

    &-info {
        font-size: 16px;
        margin-bottom: 0;
        color: #535559;
    }

    &-list {
        &-single {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}

.widget-search-form {
    position: relative;

    & input {
        border: 0;
        background: #f0f1f5;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        padding-left: 15px;
    }

    & button[type="submit"] {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background: var(--clr-theme-1);
        color: #fff;
        border: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.single-rc-post {
    overflow: hidden;

    & .thumb {
        margin-right: 20px;
        float: left;

        @include mq(xl) {
            margin-right: 11px;
        }

        @include mq(xs) {
            margin-right: 10px;
        }
    }

    & .content {
        overflow: hidden;
    }

    &-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #0a1426;
        margin-bottom: 0;

        @include mq(xl) {
            font-size: 15px;
        }

        @include mq(xs) {
            font-size: 13px;
        }

        &:hover {
            color: var(--clr-theme-1);
        }

        & a {
            text-decoration: none;
        }
    }

    &-meta {
        font-size: 13px;
        font-weight: 500;
        color: #535559;
        display: block;
    }
}

.tagcloud {
    & a {
        height: 30px;
        display: inline-block;
        border: 2px solid #e6e7eb;
        padding: 0 13px;
        text-decoration: none;
        font-size: 13px;
        border-radius: 4px;
        font-weight: 500;
        color: #535559;
        margin-bottom: 10px;
        margin-right: 2px;
        line-height: 26px;

        @include mq(xs) {
            padding: 0 7px;
            font-size: 13px;
        }

        &:hover {
            background-color: var(--clr-theme-1);
            color: #fff;
            border-color: transparent;
        }
    }
}

.post-input {
    & input {
        height: 50px;
        width: 100%;
        background: #eeeff4;
        border: 0;
        color: #707276;
        border-radius: 4px;
        font-size: 14px;
        padding: 0 25px;
    }

    & input:focus,
    & textarea:focus {
        border: 2px solid transparent;
        border-color: var(--clr-theme-1);
    }
}

.post-input {
    margin-bottom: 20px;
    line-height: 1;

    &-2 {
        margin-bottom: 30px;
    }
}

.post-input.has-textarea {
    margin-bottom: 18px;
}

.post-check {
    line-height: 1;

    & input {
        transform: translateY(1px);
        margin-right: 5px;
    }

    & label {
        font-size: 15px;
        color: #535559;

        @include mq(xs) {
            line-height: 1.5;
        }
    }
}

.sasup-submit-btn {
    background: #2a5cff;
    color: #fff;
    border: 0;
    height: 50px;
    padding: 0 23px;
    border-radius: 4px;
}