/*
--------------------------------------------
24.svg
*/
.cls-1 {
    opacity: 0.2;
}

.cls-2 {
    fill: url(#linear-gradient);
}

.cls-3 {
    fill: url(#linear-gradient-2);
}

.cls-4 {
    fill: url(#linear-gradient-3);
}

.cls-5 {
    fill: url(#linear-gradient-4);
}

.cls-6 {
    fill: url(#linear-gradient-5);
}

.cls-7 {
    fill: url(#linear-gradient-6);
}

.cls-8 {
    fill: url(#linear-gradient-7);
}

.cls-9 {
    fill: url(#linear-gradient-8);
}

.cls-10 {
    fill: url(#linear-gradient-9);
}

.cls-11 {
    fill: #fff;
    opacity: 0.02;
}

.cls-12 {
    opacity: 0.06;
    fill: url(#linear-gradient-10);
}

.cls-13 {
    fill: url(#linear-gradient-11);
}

.cls-14 {
    fill: url(#linear-gradient-12);
}

.cls-15 {
    fill: #ffa6df;
}

.cls-16 {
    fill: url(#linear-gradient-13);
}

.cls-17 {
    fill: url(#linear-gradient-14);
}

.cls-18 {
    fill: url(#linear-gradient-15);
}

.cls-19 {
    fill: url(#linear-gradient-16);
}

.cls-20 {
    fill: url(#linear-gradient-17);
}

.cls-21 {
    fill: url(#linear-gradient-18);
}

.cls-22 {
    fill: url(#linear-gradient-19);
}

.cls-23 {
    fill: url(#linear-gradient-20);
}

.cls-24 {
    fill: url(#linear-gradient-21);
}

.cls-25 {
    fill: url(#linear-gradient-22);
}

.cls-26 {
    fill: url(#linear-gradient-23);
}

.cls-27 {
    fill: url(#linear-gradient-24);
}

.cls-28 {
    fill: url(#linear-gradient-25);
}

.cls-29 {
    fill: url(#linear-gradient-26);
}

.cls-30 {
    fill: url(#linear-gradient-27);
}

.cls-31 {
    fill: url(#linear-gradient-28);
}

.cls-32 {
    fill: url(#linear-gradient-29);
}

.cls-33 {
    fill: url(#linear-gradient-30);
}

.cls-34 {
    fill: url(#linear-gradient-31);
}

.cls-35 {
    fill: url(#linear-gradient-32);
}

.cls-36 {
    fill: url(#linear-gradient-33);
}

.cls-37 {
    fill: url(#linear-gradient-34);
}

.cls-38 {
    fill: url(#linear-gradient-35);
}

.cls-39 {
    fill: url(#linear-gradient-36);
}

.cls-40 {
    fill: url(#linear-gradient-37);
}

.cls-41 {
    fill: url(#linear-gradient-38);
}

.cls-42 {
    fill: url(#linear-gradient-39);
}

.cls-43 {
    fill: url(#linear-gradient-40);
}

.cls-44 {
    fill: url(#linear-gradient-41);
}

.cls-45 {
    fill: url(#linear-gradient-42);
}

.cls-46 {
    fill: url(#linear-gradient-43);
}

.cls-47 {
    fill: url(#linear-gradient-44);
}

.cls-48 {
    fill: url(#linear-gradient-45);
}

.cls-49 {
    fill: url(#linear-gradient-46);
}

.cls-50 {
    fill: url(#linear-gradient-47);
}

.cls-51 {
    fill: url(#linear-gradient-48);
}

.cls-52 {
    fill: url(#linear-gradient-49);
}

.cls-53 {
    fill: url(#linear-gradient-50);
}

.cls-54 {
    fill: url(#linear-gradient-51);
}

.cls-55 {
    fill: url(#linear-gradient-52);
}

.cls-56 {
    fill: url(#linear-gradient-53);
}

.cls-57 {
    fill: url(#linear-gradient-54);
}

.cls-58 {
    fill: url(#linear-gradient-55);
}

.cls-59 {
    fill: url(#linear-gradient-56);
}

.cls-60 {
    fill: url(#linear-gradient-57);
}

.cls-61 {
    fill: url(#linear-gradient-58);
}

.cls-62 {
    fill: url(#linear-gradient-59);
}

.cls-63 {
    fill: url(#linear-gradient-60);
}

.cls-64 {
    fill: url(#linear-gradient-61);
}

.cls-65 {
    fill: url(#linear-gradient-62);
}

.cls-66 {
    fill: url(#linear-gradient-63);
}

.cls-67 {
    fill: url(#linear-gradient-64);
}

.cls-68 {
    fill: url(#linear-gradient-65);
}

.cls-69 {
    fill: url(#linear-gradient-66);
}

.cls-70 {
    fill: url(#linear-gradient-67);
}

.cls-71 {
    fill: url(#linear-gradient-68);
}

.cls-72 {
    fill: url(#linear-gradient-69);
}

.cls-73 {
    fill: url(#linear-gradient-70);
}

.cls-74 {
    fill: url(#linear-gradient-71);
}

.cls-75 {
    fill: url(#linear-gradient-72);
}