/*
--------------------------------------------
30.portfolio
*/
@use '../abstracts/mixins' as *;

.portfolio-btn-group {
    & button {
        color: #0a1426;
        font-size: 15px;
        font-weight: 500;
        background-color: transparent;
        padding: 0;
        border: 0;
        margin: 0 18px;
        @include transition(.3s);
        position: relative;

        @include mq(sm) {
            margin: 0 8px;
        }

        @include mq(xs) {
            margin: 0 5px;
            margin-bottom: 7px;
        }

        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            content: "";
            @include transition(.3s);
            background-color: #779eff;
        }

        &:hover,
        &.active {
            color: var(--clr-theme-1);

            &::after {
                left: 0;
                width: 100%;
            }
        }
    }
}

.portfolio-popup-link {
    & a {
        width: 45px;
        height: 45px;
        border: 1px solid #9cbdff;
        display: inline-block;
        line-height: 43px;
        color: #f8faff;
        text-align: center;
        border-radius: 50px;

        &:hover {
            background-color: #fff;
            border-color: transparent;
            color: var(--clr-theme-1);
        }
    }
}



.portfolio-info {
    &-main {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 9;
        @include transition(.3s);
        bottom: -70px;
        padding: 0 40px;
        padding-bottom: 35px;

        @include mq(lg) {
            padding: 0 25px;
            padding-bottom: 25px;
        }

        @include mq(md) {
            padding: 0 18px;
            padding-bottom: 19px;
        }

        @include mq(xs) {
            padding: 0 21px;
            padding-bottom: 18px;
        }
    }

    &-title {
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 5px;

        & a {
            text-decoration: none;
        }
    }

    &-content {
        font-size: 15px;
        margin-bottom: 0;
        color: #fff;
    }
}

.single-portfolio {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 30px 70px 0px #0005271a;

    & .portfolio-popup-link {
        position: absolute;
        right: 40px;
        top: -60px;
        opacity: 0;
        visibility: hidden;
        z-index: 9;
        transition-timing-function: ease-in-out;
        @include transition(.3s);
    }

    &::after {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0;
        @include transition(.3s);
        visibility: hidden;
        background-color: #0054B8;
    }

    &:hover {
        &::after {
            opacity: .8;
            visibility: visible;
        }

        & .portfolio-popup-link {
            opacity: 1;
            visibility: visible;
            top: 40px;
        }

        & .portfolio-info-main {
            opacity: 1;
            visibility: visible;
            bottom: 0;
        }
    }
}

// portfolio details
.portfolio-details {
    &-category-btn {
        height: 25px;
        background: #0054B8;
        line-height: 25px;
        font-size: 13px;
        color: #fff;
        padding: 0 10px;
        display: inline-block;
        border-radius: 4px;
    }

    &-title {
        font-size: 50px;
        line-height: 60px;
        color: #0a1426;
        font-weight: 700;
        margin-bottom: 0;

        @include mq(xs) {
            font-size: 24px;
            line-height: 1.3;
        }
    }
}

.portfolio-shapes {
    &>* {
        position: absolute;
    }
}

.portfolio-shape-outside {
    position: absolute;
    right: 0;
    top: 51px;

    @include mq(xs) {
        display: none;
    }
}

.portfolio-shapes {
    & img {
        &:nth-child(1) {
            right: 60px;
            top: -13px;
            -webkit-animation: hero-triangle-1 6s linear infinite;
            -moz-animation: hero-triangle-1 6s linear infinite;
            -o-animation: hero-triangle-1 6s linear infinite;
            animation: hero-triangle-1 6s linear infinite;
        }

        &:nth-child(2) {
            left: -107px;
            top: -48px;
            -webkit-animation: services-triangle 7s linear infinite alternate;
            -moz-animation: services-triangle 7s linear infinite alternate;
            -o-animation: services-triangle 7s linear infinite alternate;
            animation: services-triangle 7s linear infinite alternate;
        }

        &:nth-child(3) {
            right: 502px;
            bottom: -19px;
            -webkit-animation: services-triangle 7s linear infinite alternate;
            -moz-animation: services-triangle 7s linear infinite alternate;
            -o-animation: services-triangle 7s linear infinite alternate;
            animation: services-triangle 7s linear infinite alternate;
        }
    }
}

.portfolio-details-content-inner {
    position: relative;
    z-index: 9;
}

.portfolio-content-main-wrap {
    padding: 0 70px;

    @include mq(xs) {
        padding: 0;
    }
}

.portfolio-meta {
    &-label {
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: 600;
        color: #0a1426;
    }

    &-info {
        font-size: 15px;
        color: #535559;
        margin-bottom: 0;
    }
}

.meta-share-social {
    & a {
        font-size: 15px;
        color: #535559;
        display: inline-block;
        margin-right: 13px;

        &:hover {
            color: var(--clr-theme-1);
        }
    }
}

.portfolio-details-content {
    & p {
        font-size: 16px;
        line-height: 26px;
        color: #535559;

        & span {
            color: #0a1426;
            font-size: 20px;
            line-height: 30px;

            @include mq(xs) {
                font-size: 17px;
                line-height: 27px;
            }
        }
    }
}

.portfolio-swiper-active {
    box-shadow: 0px 30px 70px 0px #0005271a;
}

.portfolio-pagination {
    text-align: center;

    & .swiper-pagination-clickable .swiper-pagination-bullet {
        height: 6px;
        width: 18px;
        margin: 0 5px;
        @include transition(.3s);
        border-radius: 4px;

        &.swiper-pagination-bullet-active {
            width: 30px;
        }
    }
}

.portfolio-details-content-top {
    @include mq(xs) {
        padding-top: 64px;
        padding-bottom: 70px;
    }
}