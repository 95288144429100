/*
--------------------------------------------
8.form
*/
@use '../abstracts/mixins' as *;

.sasup-hero-form {
    width: 470px;
    background: var(--clr-common-white);
    display: inline-block;
    border-radius: 10px;
    padding: 8px;
    @include mq(xs) {
        width: 100%;
    }
    &-inner {
        height: 44px;
    }
    & input[type="text"] {
        width: 100%;
        border: 0;
        padding-left: 16px;
        background-color: transparent;
    }
    & input[type='submit'] {
        height: 44px;
        border: 0;
        background:#ebebeb;
        color: #fff;
        font-size: 15px;
        line-height: 44px;
        display: inline-block;
        position: absolute;
        right: 0;
        @include transition(.3s);
        top: 50%;
        transform: translateY(-50%);
        padding: 0 22px;
        border-radius: 4px;
        font-weight: 500;
        &:hover {
            background-color: #F9B44D;
            color:#000;
        }
    }
    & .epix-hero-form-label {
        position: absolute;
        left: calc(24px - 8px);
        top: 50%;
        font-size: 15px;
        font-weight: 300;
        cursor: auto;
        @include transition(.1s);
        color: #717484;
        transform: translateY(-50%);
        @include mq(xs) {
            font-size: 13px;
            left: calc(24px - 19px);
        }
    }
}
.epix-hero-form-label.epix-hero-form-clicked {
    transform: translateY(0);
    top: -22px;
    color: #fff;
    font-weight: 600;
    background: #ebebeb;
    padding: 3px 19px;
    border-radius: 4px;
}

.started-label {
    & label {
        font-size: 16px;
        color: rgb(238,245,219);
        display: block;
        line-height: 1;
        font-weight:600;
    }
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
}

// hero form
.hero-form-2 {
    position: relative;
    display: inline-block;
    & input {
        width: 400px;
        padding-left: 24px;
        height: 55px;
        display: block;
        padding-right: 140px;
        border-radius: 4px;
        border: 0;
        @include mq(xs) {
            width: 100%;
        }
    }
    & .email-label-2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
        @include transition(.3s);
        cursor: unset;
    }
    & button[type="submit"] {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.rectangle-btn-2 {
    border: 0;
    background-color: #E97472;
    color: #fff;
    height: 55px;
    width: 140px;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    @include mq(xs) {
        width: 123px;
    }
}
.email-label-2 {
    font-size: 14px;
    color: #9b989f;
}
.sasup-trial-link {
    font-size: 13px;
    font-weight: 500;
    color: #ada6cd;
    display: block;
    margin-bottom: 0;
    & a {
        color: #fc4199;
        text-decoration: none;
        & i {
            font-size: 17px;
            vertical-align: middle;
            margin-left: 3px;
        }
    }
}
.mb-12 {
    margin-bottom: 12px;
}
.sasup-s-form-2 {
    position: relative;
    display: inline-block;
    @include mq(xs) {
        display: block;
    }
    & input {
        width: 400px;
        border: 0;
        background-color: #fff;
        border-radius: 4px;
        height: 60px;
        padding: 0 22px;
        font-size: 14px;
        font-weight: 300;
        color: #535559;
        @include mq(xs) {
            width: 100%;
        }
        @include placeholder {
            font-size: 14px;
            font-weight: 300;
            color: #535559;
        }
    }
    & button {
        height: 60px;
        padding: 0 20px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #E97472;
        color: #fff;
        border: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        font-size: 15px;
        @include transition(.3s);
        font-weight: 500;
        &:hover {
            background-color: var(--clr-theme-1);
        }
    }
}
.newsletter-form-input {
    position: relative;
    & input {
        height: 50px;
        border-radius: 4px;
        padding-left: 25px;
        border: 0;
        line-height: 50px;
        width: 100%;
        background-color: #fff;
        font-size: 14px;
        font-weight: 300;
        color: #535559;
        @include placeholder {
            font-size: 14px;
            font-weight: 300;
            color: #535559;
        }
    }
}
.newsletter-form {
    position: relative;
    & button[type="submit"] {
        height: 50px;
        padding: 0 15px;
        background: var(--clr-theme-1);
        color: #fff;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 0;
        position: absolute;
        font-size: 15px;
        right: 0;
        top: 0;
        font-weight: 500;
    }
}
