/*
--------------------------------------------
10.feature
*/
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
.sasup-single-feature {
    @include transition(.3s);
    border-radius: 4px;
    padding: 50px;
    @include mq(lg) {
        padding: 50px 35px;
    }
    @include mq(md) {
        padding: 40px 32px;
    }
    @include mq(xs) {
        padding: 30px;
    }
    & .sasup-feature-title {
        font-size: 24px;
        line-height: 1.417;
        font-weight: 500;
        margin-bottom: 25px;
        @include mq(md) {
            font-size: 17px;
            margin-bottom: 19px;
        }
        @include mq(xs) {
            font-size: 18px;
            margin-bottom: 15px;
        }
        & a {
            text-decoration: none;
        }
        &:hover {
            & a {
                color: var(--clr-theme-1);
            }
        }
    }
    &:hover {
        transform: scale(1.04);
        box-shadow: 0px 30px 50px 0px rgba(#000527, 0.1);
        & .sasup-feature-logo {
            & img {
                animation-name: animation-pulse-shrink;
                animation-duration: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
        }
    }
}
.read-more-btn {
    font-size: 15px;
    text-decoration: none;
    font-weight: 500;
    color: #0a1426;
    & i {
        font-size: 12px;
        margin-left: 5px;
        font-weight: 500;
    }
    &:hover {
        color: var(--clr-theme-1);
    }
}
.feature-icon-2 {
    margin-bottom: 35px;
    @include mq(lg) {
        margin-bottom: 25px;
    }
}
.feature-content {
    &-title-2 {
        font-size: 20px;
        margin-bottom: 20px;
        color: #0a1426;
        & a {
            text-decoration: none;
        }
        &:hover {
            color: var(--clr-theme-1);
        }
    }
    &-desc-2 {
        font-size: 15px;
        line-height: 24px;
        color: #535559;
        margin-bottom: 35px;
    }
}
.icon-box-rounded {
    width: 46px;
    height: 46px;
    display: inline-block;
    text-decoration: none;
    line-height: 46px;
    text-align: center;
    border: 2px solid #e6e7e9;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    & i {
        font-weight: 300;
        color: #182233;
        @include transition(.3s);
        &:first-child {
            transform: translateY(-40px);
        }
        &:nth-child(2) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
    &:hover {
        background-color: var(--clr-theme-1);
        border-color: transparent;
        & i {
            color: var(--clr-common-white);
            &:first-child {
                transform: translateY(0);
            }
            &:nth-child(2) {
                top: calc(50% + 40px);
            }
        }
    }
}
.single-feature-2 {
    padding: 50px 40px;
    padding-bottom: 42px;
    transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0px 30px 70px 0px rgba(#000527, .1);
    @include mq(lg) {
        padding: 30px 24px;
    }
    @include mq(xs) {
        padding: 40px 25px;
    }
    &:hover {
        box-shadow: 0px 30px 90px 0px rgba(#000527, .25);
        & .feature-icon-2 {
           & img {
                -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                -webkit-transform: translate3d(0, 0, 0);
           }
        }
    }
}
.feature-link-2 {
    & a {
        font-size: 15px;
        font-weight: 500;
        color: #535559;
        text-decoration: none;
        & i {
            font-size: 13px;
            color: #535559;
            font-weight: 500;
            @include transition(.3s);
            margin-left: 5px;
        }
        &:hover {
            color: var(--clr-theme-1);
            & i {
                color: var(--clr-theme-1);
            }
        }
    }
}
.listing-feature {
    &-content {
        &-title {
            font-size: 18px;
            font-weight: 700;
            color: #0a1426;
            margin-bottom: 10px;
        }
        &-desc {
            font-size: 15px;
            line-height: 22px;
            color: #535559;
            margin-bottom: 0;
        }
    }
}
.listing-row {
    --bs-gutter-x: 16px;
}
.single-listing-feature {
    padding-right: 4px;
    &:hover {
        & .listing-feature-icon {
            -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            -webkit-transform: translate3d(0, 0, 0);
        }
    }
}
.video-link {
    &-img {
        position: relative;
        display: inline-block;
        border-radius: 4px;
        overflow: hidden;
        &::after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 150px;
            background-image: -moz-linear-gradient( 90deg, rgba(#0a1426, .8) 0%, rgba(#0a1426, .8) 0%, rgba(#0a142600, 0) 100%);
            background-image: -webkit-linear-gradient( 90deg, rgba(#0a1426, .8) 0%, rgba(#0a1426, .8) 0%, rgba(#0a142600, 0) 100%);
            background-image: -ms-linear-gradient( 90deg, rgba(#0a1426, .8) 0%, rgba(#0a1426, .8) 0%, rgba(#0a142600, 0) 100%);
            content: "";
        }
        & img {
            @include transition(.3s);
        }
        &:hover {
            & img {
                transform: scale(1.1);
            }
        }
    }
    &-btn {
        text-decoration: none;
        & i {
            font-size: 16px;
            margin-left: 4px;
            font-weight: 700;
            width: 50px;
            height: 50px;
            display: inline-block;
            @include transition(.3s);
            background: #0054B8;
            text-align: center;
            line-height: 52px;
            border-radius: 50%;
            color: #fff;
            &::before {
                margin-left: 5px;
            }
        }
        & span {
            font-size: 14px;
            color: #fff;
            font-weight: 500;
            @include transition(.3s);
        }
        &:hover {
            & i {
                background-color: #0054B8;
            }
            & span {
                color: #0054B8;
            }
        }
    }
    &-action {
        position: absolute;
        bottom: 30px;
        left: 30px;
        z-index: 9;
    }
}

.features-space-1 {
    @include mq(xs) {
        padding-top: 90px;
    }
}
.has-feature-shadow {
    box-shadow: 0px 30px 50px 0px #0005271a;
}



/**
* feature style 4
*/
.sasup-features {
    &-box {
        &-3d-img {
            &-4-2 {
                margin-left: -26px;
                & img {
                    -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    animation-duration: 5.6s;
                }
                &-2 {
                    position: absolute;
                    bottom: -30px;
                    right: 0;
                    & img {
                        max-width: inherit;
                    }
                }
            }
            &-4-3 {
                filter: drop-shadow(0px 1px 4px rgba(48, 0, 74, 0.06));
                border-radius: 10px;
                &-img {
                    transform: translateX(-60px) translateY(-11px);
                    & img {
                        max-width: inherit;
                        -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
                        -moz-animation: hero-thumb-animation 2s linear infinite alternate;
                        -o-animation: hero-thumb-animation 2s linear infinite alternate;
                        animation: hero-thumb-animation 2s linear infinite alternate;
                    }
                }
            }
            &-4-2-3 {
                border-radius: 10px;
                filter: drop-shadow(0px -10px 20px rgba(19, 15, 64, 0.04)) drop-shadow(0px 30px 50px rgba(83, 0, 0, 0.08));
                margin-left: 90px;
                margin-top: -70px;
                overflow: hidden;
            }
        }
        &-4 {
            border-radius: 10px;
            padding: 43px 50px;
            padding-bottom: 60px;
            position: relative;
            @include mq(md) {
                padding-bottom: 260px;
            }
            &.style-2 {
                padding-bottom: 45px;
                padding-top: 35px;
            }
            &.style-3 {
                padding: 43px 40px;
                padding-bottom: 60px
            }
            &.style-4 {
                padding: 43px 40px;
                padding-bottom: 52px;
            }
        }
        &-title-4 {
            font-family: $space;
            font-weight: 700;
            font-size: 26px;
            letter-spacing: -0.04em;
            color: var(--clr-common-black-2);
            margin-bottom: 7px;
            line-height: 1.3;
            & a {
                text-decoration: none;
                &:hover {
                    color: var(--clr-theme-1);
                }
            }
        }
        &-content {
            &-img {
                &-left-4 {
                    float: left;
                    margin-right: 11px;
                    & img {
                        -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                        -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                        -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                        animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                    }
                }
                &-wrap-4 {
                    overflow: hidden;
                    & .item-1 {
                        filter: drop-shadow(0px 2px 6px rgba(48, 0, 74, 0.06));
                        overflow: hidden;
                    }
                    & .item-2 {
                        filter: drop-shadow(0px -10px 20px rgba(19, 15, 64, 0.04)) drop-shadow(0px 30px 50px rgba(19, 15, 64, 0.05));
                        margin-left: 70px;
                        margin-top: -50px;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    &-message-img-4 {
        &-1 {
            margin-left: -10px;
            overflow: hidden;
            & img {
                max-width: inherit;
            }
        }
        &-2 {
            webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
            -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
            -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
            animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
            animation-duration: 5.6s;
            & img {
                max-width: inherit;
                transform: translateX(6px) translateY(51px);
            }
        }
    }
}
.has-feature-top-bg {
    position: relative;
    & > * {
        position: relative;
        z-index: 3;;
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 400px;
        background: linear-gradient(180deg, #F3F8FF 0%, rgba(248, 251, 255, 0.2) 100%);
        content: "";
    }
}