/*
--------------------------------------------
28.brand
*/
@use '../abstracts/mixins' as *;
.single-brand {
    & img {
        @include transition(.3s);
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0);
        }
    }
}
.mb-28 {
    margin-bottom: 28px;
}

/**
* brand style 4
*/
