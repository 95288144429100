/*
--------------------------------------------
18.creative_task
*/
@use '../abstracts/mixins' as *;

.creative-task-tab-controls {
    float:right;
}
#pills-tab {
    display:inline-flex;
}
@keyframes circleAnimation {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  .loading-container {
    position: relative; /* Establish a positioning context */
    width: [desired size]; /* Set desired size */
    height: 110px; /* Set desired size */
    width: 110px; /* Set desired size */
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-container svg {
    position: absolute;
    transform: rotate(-90deg); /* Rotate the circle to start animation from the top */
    animation: circleAnimation 2s linear forwards; /* Adjust 2s to desired animation duration */
    top:0;
    left:0;
    padding:0 !important;
  }
  
  .circle {
    stroke-dasharray: 176; /* Replace with actual calculated circumference based on radius */
    stroke-dashoffset: 176; /* Replace with actual calculated circumference */
    stroke-width: 2;
    stroke: white;
    fill: transparent;
  }
  
  .icon-container {
    position: absolute;
    width:60px;
    height:60px;
    text-align:center;
    top:13px;
    font-weight:300;
    color:white;
    font-size:24px;
    left:0;
    z-index: 1; /* Ensure the icon is above the SVG circle */
    /* Additional styling for the icon can go here */
    
    & svg {
        transform: rotate(0);
        padding:15px !important;
    }
  }

.tab-pane.fade.row {
    opacity: 0;
    transition: opacity .05 ease !important;
}

.tab-pane.fade.show.active.row {
    opacity: 1;
    transition: opacity .05 ease !important;
}

.creative-task {
    &-tab {
        
        &-controls {
            
            & button {
                height: 36px;
                background: #fff;
                color: #000;
                border:none;
                padding: 0 40px;
                margin-left: 10px;
                @include transition(.3s);
                font-size: 14px;
                border-radius: 4px;
                min-width:100px;

                @include mq(lg) {
                    padding: 0 30px;
                }

                @include mq(md) {
                    padding: 0 30px;
                }

                @include mq(sm) {
                    padding: 0 30px;
                    margin-left: auto;
                    margin-right: 10px;
                }

                @include mq(xs) {
                    margin-left: auto;
                    margin-right: 6px;
                    padding: 0 20px;
                    margin-bottom: 10px;
                }

                &.active {
                    background-color: #0054B8;
                    border-color: transparent;
                    color:white;
                }

                & i {
                    font-size: 11px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;

                    @include mq(md) {
                        margin-right: 9px;
                    }

                    @include mq(xs) {
                        margin-right: 8px;
                    }
                }

                &:hover {
                    background-color: #0054B8;
                    color: #fff;
                    border-color: transparent;
                }
            }
        }
    }

    &-dashbord {
        &-wrap {
            padding: 70px;

            @include mq(lg) {
                padding: 50px 40px;
            }

            @include mq(sm) {
                padding: 40px;
            }

            @include mq(xs) {
                padding: 25px;
            }

            & .icon {
                border-radius: 50%;
                
                background-color: #0054B8;
                width:60px;
                height:60px;

                & svg {
                    width: 60px;
                    height: 60px; /* Set a fixed height to match the width */
                    color: #fff;

                    
                    box-sizing: border-box; /* Ensure padding is included in the width and height */
                    padding: 10px; /* Adjust padding as needed */
                    display: flex; /* Use flexbox to center the icon */
                    align-items: center;
                    justify-content: center;
                    
                }
                margin-bottom: 34px;
            }

            & .content {
                padding-bottom: 26px;
            }
        }
    }

    &-title {
        font-weight: 600;
        color: #000;
        display: block;
        margin-bottom: 11px;
    }

    &-content {
        font-size: 14px;
        line-height: 24px;
    }
}

.creative-task-service-list {
    & ul {
        & li {
            list-style: none;
            display: block;
            font-size: 14px;

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            & i {
                width: 20px;
                height: 20px;
                line-height: 20px;
                display: inline-block;
                background-color: #fff;
                color:#0054B8;
                font-weight:900;
                border-radius: 50%;
                margin-right: 8px;
                text-align: center;
                font-size: 10px;
            }
        }
    }
}
.creative-task-dashbord {
    border-radius:10px 10px 0px 0px;
}
.creative-task-dashbord-wrap {
    @include mq(md) {
        padding: 60px;
    }

    border-radius:10px;

    & .row {
        

        &>.item-1 {
            width: 34.666667%;

            @include mq(lg) {
                width: 43.666667%;
            }

            @include mq(md) {
                width: 100%;
                margin-bottom: 60px;
            }

            @include mq(sm) {
                width: 100%;
                margin-bottom: 60px;
            }

            @include mq(xs) {
                width: 100%;
                margin-bottom: 60px;
            }
        }

        &>.item-2 {
            width: calc(100% - 34.666667%);

            @include mq(lg) {
                width: calc(100% - 43.666667%);
            }

            @include mq(md) {
                width: 100%;
            }

            @include mq(sm) {
                width: 100%;
            }

            @include mq(xs) {
                width: 100%;
            }
        }
    }
}

.gradient-dashbord-box {
    background-image: -moz-radial-gradient(center, ellipse cover, rgb(252, 232, 197) 0%, #fff 70%);
    background-image: -webkit-radial-gradient(center, ellipse cover, rgb(252, 232, 197) 0%, #fff 70%);
    background-image: -ms-radial-gradient(center, ellipse cover, rgb(252, 232, 197) 0%, #fff 70%);
    background-image: -o-radial-gradient(center, ellipse cover, rgb(252, 232, 197) 0%, #fff 70%);

    background-image: radial-gradient(ellipse at center, #FCE8C5, #fff 70%);
    
    
    padding: 60px;
    opacity: 44;

    @include mq(lg) {
        padding: 30px;
    }

    @include mq(sm) {
        padding: 25px;
    }

    @include mq(xs) {
        padding: 20px;
    }
}