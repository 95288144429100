/*
--------------------------------------------
19.pricing
*/
@use '../abstracts/mixins' as *;

.sub-header-pringing-info {
    font-weight:900;
    margin-bottom:30px;
    
}

h3.price-headline {
    font-size: 36px !important;
    font-weight: 600;
    color: #0054B8;

    
    @include mq(xs) {
        font-size: 23px !important;
    }
    @include mq(sm) {
        font-size: 23px !important;
    }
    @include mq(md) {
        font-size: 28px !important;
    }

    @include mq(xl) {
        font-size: 32px !important;
    }

    @include mq(xxl) {
        font-size: 36px !important;
    }

}

.pricing-tab-top {


    position: static; //this was sticky but we're not doing that anymore as we made the prices larger and it made seeing the details too tricky
    top: -105px;
    background-color: white;
    z-index: 10;

    //large screens
    @include mq(lg) {
        top: -90px;
    }

    @include mq(md) {
        top: -125px;
    }

    @include mq(sm) {
        top: 0;
        position: relative;
    }

    @include mq(xs) {
        top: 0;
        position: relative;
    }

}

.pricing-tab-top-long-scroll {
    position: relative;
    top:0;
}

.pricing-feature {
    list-style-image: url('/assets/img/checkMark.svg');
    margin-top:15px;
}

.pricing-feature-blank {
    list-style-image: url('/assets/img/checkMark.svg');
    margin-top:15px;
    opacity:0;
}

.pricing-tab-control {
    & .nav-item {
        & button {
            border: 0;
            background: transparent;
            font-size: 14px;
            color: #535559;
            font-weight: 500;
            margin: 0;
            padding: 0 calc(110px / 2);
            position: relative;

            &::before {
                width: 80px;
                height: 40px;
                content: "";
                position: absolute;
                left: -38px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 30px;
                pointer-events: none;
                cursor: pointer;

                box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.7);

                @include mq(xs) {
                    //display: none;
                }

                @include mq(sm) {
                    display: block;
                    left: -28px;
                }

                background-color: black;

            }

            &::after {
                position: absolute;
                left: -34px;
                top: 50%;
                transform: translateY(-50%);
                width: 32px;
                height: 32px;
                background-color: var(--clr-common-white);
                border-radius: 50%;
                content: "";
                @include transition(.3s);

                @include mq(xs) {
                    //display: none;
                }

                @include mq(sm) {
                    display: block;
                    left: -23px;
                }
            }



            &.active {
                color: var(--clr-body-text);

                text-decoration: none;
                text-decoration-color: #0054B8;

                
                &::before {
                    background-color: #0054B8;
                }
            
                

                &::after {
                    left: 7px;

                    @include mq(xl) {
                        left: 7px;
                    }

                    @include mq(lg) {
                        left: 7px;
                    }

                    @include mq(sm) {
                        left: 15px;
                        
                    }
                }
            }

            
        }

        
        
        

        //second button
        &:nth-child(2) {
            & button {
                
                

                &::before {
                    
                }

                &::after {
                                        
                }
            }
        }

        &:first-child {
            button {
                &::after {
                    
                    display: none;
                }

                &::before {
                    display: none;
                }
            }
        }
    }


    // &.style-2 {
    //     & button.annual-tab-btn::before {
    //         background-color: #23CE6B;
    //     }

    //     & button.active {
    //         color: #23CE6B;
    //     }
    // }
}

.lets-chet {
    font-size: 24px;
    font-weight: 500;
    color: #0a1426;
    margin-bottom: 68px;
}

.pricing-shapes {
    display:none !important; /* disabling for now */
    opacity: 0;
    visibility: hidden;
    @include transition(.3s);

    &.show-shapes {
        opacity: 1;
        visibility: visible;
    }

    &>* {
        position: absolute;
    }

    &>.shape-1 {
        left: 47px;
        top: 165px;

        & img {
            -webkit-animation: services-triangle-2 4s linear infinite alternate;
            -moz-animation: services-triangle-2 4s linear infinite alternate;
            -o-animation: services-triangle-2 4s linear infinite alternate;
            animation: services-triangle-2 4s linear infinite alternate;
        }
    }

    &>.shape-2 {
        right: 47px;
        top: 180px;

        & img {
            -webkit-animation: services-triangle-2 4s linear infinite alternate;
            -moz-animation: services-triangle-2 4s linear infinite alternate;
            -o-animation: services-triangle-2 4s linear infinite alternate;
            animation: services-triangle-2 4s linear infinite alternate;
        }
    }

    &>.shape-3 {
        left: 33px;
        line-height: 1;
        top: 160px;

        & img {
            -webkit-animation: services-circle-2 1.5s linear 0s infinite alternate;
            -moz-animation: services-circle-2 1.5s linear 0s infinite alternate;
            -o-animation: services-circle-2 1.5s linear 0s infinite alternate;
            animation: services-circle-2 1.5s linear 0s infinite alternate;
        }
    }

    &>.shape-4 {
        left: 30px;
        top: 185px;

        & img {
            -webkit-animation: hero-triangle-1 6s linear infinite;
            -moz-animation: hero-triangle-1 6s linear infinite;
            -o-animation: hero-triangle-1 6s linear infinite;
            animation: hero-triangle-1 6s linear infinite;
        }
    }

    &>.shape-5 {
        right: 30px;
        top: 159px;

        & img {
            -webkit-animation: hero-triangle-1 6s linear infinite;
            -moz-animation: hero-triangle-1 6s linear infinite;
            -o-animation: hero-triangle-1 6s linear infinite;
            animation: hero-triangle-1 6s linear infinite;
        }
    }

    &>.shape-6 {
        top: 190px;
        right: 34px;

        & img {
            -webkit-animation: services-circle-2 1.5s linear 0s infinite alternate;
            -moz-animation: services-circle-2 1.5s linear 0s infinite alternate;
            -o-animation: services-circle-2 1.5s linear 0s infinite alternate;
            animation: services-circle-2 1.5s linear 0s infinite alternate;
        }
    }
}

.selling-point {
    font-size: 10px !important;
    padding:8px;

    @include mq(md) {
        font-size: 8px !important;
        padding:6px !important;
    }


    @include mq(xs) {
        font-size: 10px !important;
        padding:6px;
    }
}

.single-price-wrap {
    a {
        &:not(.feature-item *) {  // Only apply if parent is not selling-point
            padding: 20px !important;

            @include mq(md) {
                font-size: 12px !important;
                padding: 10px !important;
            }

            @include mq(sm) {
                font-size: 10px !important;
                padding: 25px !important;
            }

            @include mq(xs) {
                font-size: 16px !important;
            }
        }

        &.no-padding-link {
            padding: 0 !important;
        }
    }
}

.headline {
    font-size: 22px !important;
}

.single-pricing {
    


    &-title {
    
        font-weight: 600;
        font-size: 16px;
        color: #0a1426;
        margin-bottom: 9px;

        @include mq(md) {
            font-size: 16px;
        }

        @include mq(sm) {
            font-size: 14px;
        }




    }

    &-info {
        font-size: 14px;
        line-height: 20px;
        color: #535559;
        margin-bottom: 50px;

        &.space-last {
            margin-bottom: 57px;
        }
    }

    &-amount {
        font-size: 54px;
        color: #0a1426;
        font-weight: 500;
        margin-bottom: 0;
    }

    &-label {
        font-size: 15px;
        font-weight: 500;
        color: #0a1426;
        display: block;
        margin-bottom: 35px;
    }

}

//.col-12, .col-sm-12, .col-md-4, .col-lg-4, .col-xl-3, .col-xxl-3 {
    .single-price {
        border-top: 1px solid #e0e0e0;
        

        &:first-child {
            border-left: 1px solid #e0e0e0;
        }

        @include mq(xs) {
            border: 1px solid #e0e0e0 !important;
            border-radius:10px !important;
        }


        

        
            
        //border-right: 1px solid #e0e0e0;
            
        

    }

    



.single-price {
    position: relative;
    border-radius: 4px 4px 0 0;
    //box-shadow: 0px 30px 70px 0px #0005271a;
    padding: 20px 20px 0px !important;
    position: relative;
    //min-height: 370px;
    background-color: #fff;
    

    @include mq(xl) {
        padding: 50px 25px;
    }

    &:hover {
        & .pricing-shapes {
            opacity: 1;
            visibility: visible;
        }
    }

    .sasup-gray-border-btn {
        font-weight:600 !important;
        font-size:18px;
    }
    

    &::after {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 5px;
        width: 170px;
        background-color: transparent;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        content: "";
    }

    & .selling-point {
        display: none;
    }

    &.item-1 {
        &::after {
            background-color: #E97472;
        }
    }

    &.item-2-1 {
        &::after {
            background-color: #ebebeb;
        }
    }

    &.item-2 {
        &::after {
            background-color: #33dbdb;
        }
    }

    &.item-2-2 {
        //border: 2px solid #0054B8;

        //selling point class
        & div.selling-point {
            display:inherit;
        }
        &::after {
            
            
        }
    }

    &.item-3 {
        &::after {
            background-color: #af85ff;
        }
    }

    &.item-2-3 {
        &::after {
            background-color: #ebebeb;
        }
    }

    &.item-4 {
        &::after {
            background-color: #fb5050;
        }
    }

    &.item-2-4 {
        &::after {
            
        }
    }
}

.single-price-wrap {
    position: relative;
    z-index: 9;
}

.pricing-tab-extra-info {
    &>* {
        margin: 0 10px;
    }

    & .icon {
        width: 35px;
        height: 35px;
        background: #eaebf2;
        margin-top: 5px;
        border-radius: 50%;
        line-height: 33px;
        vertical-align: top;
    }

    & .content {
        & p {
            font-size: 14px;
            line-height: 22px;
            color: #535559;
            margin-bottom: 0;

            & br {
                @include mq(xs) {
                    display: none;
                }
            }
        }
    }
}

.pricing-space {
    @include mq(xs) {
        padding-bottom: 90px;
    }
}

.space-last {
    margin-bottom: 40px;
}