/* Terms Conditions Template - Start
================================================== */

@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.terms_conditions_content {
	padding: 40px;
	background-color: rgb(242, 243, 248);
	border-radius: 10px;

	@include mq(xs) {
		padding: 30px;
	}

	.warpper_title {
		font-size: 38px;
		line-height: 1.3;
		margin-bottom: 30px;

		@include mq(xs) {
			font-size: 28px;
		}

		@include mq(sm) {
			font-size: 28px;
		}

		@include mq(lg) {
			font-size: 28px;
		}
	}

	p {
		font-size: 17px;
		line-height: 27px;
		margin-bottom: 30px;
	}

	.info_title {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.icon_list {
		margin-bottom: 40px;
	}

	.icon_list.unordered_list_block>li:not(:last-child) {
		margin-bottom: 14px;
	}

	.icon_list .list_item_text {
		color: #0a1426;
	}

	.icon_list .list_item_icon {
		flex: none;
		width: auto;
		height: auto;
		font-size: 5px;
		border-radius: 0;
		margin-inline-end: 10px;
		color: #0a1426;
		background-color: transparent;
		display: none;
	}

	.link {
		font-size: 17px;
		font-weight: 600;
		line-height: 27px;
		color: #0054B8;
		text-decoration: underline;
	}

	.author_mail {
		color: #0a1426;

		&:hover {
			color: #0054B8;
		}
	}
}

.nav.tabs_nav_boxed {
	padding: 30px 0;
	border-radius: 12px;
	border: 1px solid hsla(0, 3%, 6%, .1);
}

.tabs_nav_boxed button {
	display: flex;
	font-size: 16px;
	font-weight: 500;
	line-height: 27px;
	padding: 10px 40px;
	position: relative;
	align-items: center;
	border: none;
	background-color: #ffffff;
}

.tabs_nav_boxed button i {
	opacity: 0;
	font-size: 10px;
	margin-right: 10px;
	line-height: 1;
	color: #0054B8;
	transform: translateX(-10px);
	transition: .3s cubic-bezier(.5, 1, .89, 1);
}

.tabs_nav_boxed button.active i {
	opacity: 1;
	transform: translateX(0);
}

.tabs_nav_boxed button.active,
.tabs_nav_boxed button:hover {
	color: #0054B8;
}

/* Terms Conditions Template - End
================================================== */